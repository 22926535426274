import React from "react";
import { Grid, Typography, Container, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        // height: '100vh',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3) 
    },
    section: {
        marginTop: theme.spacing(3) 
    }
}))
const Privacy = () => {
    const classes = useStyles();
    const { t } = useTranslation('privacy');

    return (
        <>
            <Helmet>
                <title>
                    {t('metaTitle')}
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Container maxWidth="xl" className={classes.container}>
                <Box my={4}>
                    <Typography variant="h1" align="left" gutterBottom>
                        {t('title')}
                    </Typography>
                </Box>

                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section1.header')} 
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section1.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('section1.text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section2.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section2.listHeader')}
                        </Typography>
                        <Box mt={2}>
                            <ul>
                                {
                                    t('section2.list', {returnObjects: true}).map((item, idx) => 
                                        <li key={idx}><Typography variant="body1">{item}</Typography></li>
                                    )
                                }
                            </ul>
                        </Box>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section4.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section4.listHeader')}
                        </Typography>
                        <Box mt={2}>
                            <ul>
                                {
                                    t('section4.list', {returnObjects: true}).map((item, idx) => 
                                        <li key={idx}><Typography variant="body1">{item}</Typography></li>
                                    )
                                }
                            </ul>
                        </Box>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section5.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section5.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section6.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section6.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section7.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section7.listHeader')}
                        </Typography>
                        <Box mt={2}>
                            <ul>
                                {
                                    t('section7.list', {returnObjects: true}).map((item, idx) => 
                                        <li key={idx}><Typography variant="body1">{item}</Typography></li>
                                    )
                                }
                            </ul>
                        </Box>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section8.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section8.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section8.text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section9.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section9.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section10.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="privacy:section10.listHeader">
                                We are committed to treating you with respect and courtesy, and to providing the highest level of service. Please contact our Privacy Officer at 1-800-844-6790 or email <a href="mailto:privacy@cwbgroup.org">privacy@cwbgroup.org</a> if you:
                            </Trans>
                        
                        </Typography>
                        <Box my={2}>
                            <ul>
                                {
                                    t('section10.list', {returnObjects: true}).map((item, idx) => 
                                        <li key={idx}><Typography variant="body1">{item}</Typography></li>
                                    )
                                }
                            </ul>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section10.text1')}
                        </Typography>
                    </Box>
                </section>
            </Container>
        </>
    );
};

export default Privacy;