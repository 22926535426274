import React from 'react'
import { Table, TableHead, TableRow, TableCell, Typography,Box,useMediaQuery, } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    tableContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        overflow:'hidden',
        backgroundColor: '#FFF',
        //paddingBottom: '12px',
    },
    table: {
        tableLayout: 'fixed',
        '& thead th': {
            wordWap: 'break-word',
            fontWeight: '600',
            color: theme.palette.secondary.main,
            
        },
        '& tbody td': {
            wordWrap: 'break-word',
            fontWeight: '300'
        },
        '& tbody tr:hover': {
       
        },
        '& .MuiTableCell-root':{
            borderBottom: 'none',
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    
}))

function useTable( headCells) {
    const classes =  useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const TblContainer = props => (
        <div className={classes.tableContainer}>
        <Table className={classes.table}>
            {props.children}
        </Table>
        </div>
    )

    const TblHead = props => {
        return (
            <TableHead>
                <TableRow>
                    {
                        !isMobile &&
                        headCells.map(headCell => (
                        <TableCell key={headCell.id}>
                            <Typography align="center" variant="body1">
                               
                                    <b>{headCell.label}</b>
                              
                            </Typography>
                            
                        </TableCell>))
                    }
                    {
                        isMobile &&
                        <TableCell key={headCells[0].id}>
                            <Typography align="center" variant="body1">
                                
                                    <b>{headCells[0].label}</b>
                               
                            </Typography>
                        </TableCell>
                    }
                </TableRow>
            </TableHead>
        )
    }

    return {
        TblContainer,
        TblHead
    }
}

export default useTable
