import request from './base';
import {excludeObjNullValue} from '../utils/common';

export const login = (payload) => request.postJSON(
    '/login',
    excludeObjNullValue({
        email: payload.email,
        password: payload.password,
    })
);

export const signup = (payload) => request.postJSON(
    '/signup',
    excludeObjNullValue({
        email: payload.email,
        password: payload.password,
        country: payload.country,
        agree: payload.agree,
        qualification_before: payload.qualification,
        opt_in_email: payload.optInEmail
    })
);

export const getCurrentUser = () => request.getJSON('/currentuser');


export const changePassword = (newPassword, oldPassword) => request.postJSON(
    '/changepassword',
    {
        password: newPassword,
        old_password: oldPassword
    });

export const changePasswordWithToken = (newPassword, token) => request.postJSON(
    '/passwordreset', {
        password: newPassword,
        token: token
    }
);

export const emailPasswordReset = (emailAddress) =>
    request.getJSON('/forgotpassword',
        {email: emailAddress});

