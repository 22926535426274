import React from 'react'
import { FormControl, FormControlLabel, makeStyles, Checkbox as MuiCheckbox, FormHelperText } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
    
        '& .MuiCheckbox-root': {
            color: theme.palette.secondary.main,
        },
        '& .MuiTypography-body1': {
            fontSize: '0.9rem',
        }
    }
}));
export default function Checkbox(props) {
    const classes = useStyles();
    const { name, label, value, onChange, error=null } = props;

    const convertToDefaultEventParameter = (name, value) => ({
        target: {
            name, value
        }
    })
    return (
        <FormControl className={classes.root}
        {...(error && {error:true})}

        >
            <FormControlLabel
                control={
                    <MuiCheckbox 
                    name={name}
                    checked={value}
                    onChange={e => onChange(convertToDefaultEventParameter(name, e.target.checked))}
                    />
                }
                label={label}
            />
            {
                error && 
                <FormHelperText>
                    {error}
                </FormHelperText>
            }
        </FormControl>
    )
}
