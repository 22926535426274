import React from 'react'
import { TextField, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormLabel-root':{
            color: theme.palette.text.primary,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '1rem',
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main
            }
            //border: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main
        }
    }
}));
const Input = (props) => {
    const classes = useStyles();
    const { name, label, value, error=null, onChange, ...other } = props;
    return (
        <TextField
            className={classes.root} 
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
            {...(error && {error:true, helperText: error})}
            rows={4}
        />
    )
}

export default Input
