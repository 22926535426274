import React, {useState} from "react";
import { Grid, Typography, Container, Box, Button, Link, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useForm, Form} from '../components/useForm';
import Input from '../components/Input';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import iconFacebook from '../assets/icons/icon-facebook.png';
import iconLinkedin from '../assets/icons/icon-linkedin.png';
import iconTwitter from '../assets/icons/icon-twitter.png';
import * as api from '../api';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";
const initialValues = {
    email: '',
    message: ''
}

const useStyles = makeStyles((theme) => ({
    container: {
        // height: '100vh',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3) 
    },
    icon: {
        height: '40px',
        width: '40px',
        //marginRight: theme.spacing(2)
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}))

const Contact = () => {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const { t } = useTranslation(['contact', 'error']);

    const validate = () => {
        let temp = {};
        const re = /\S+@\S+\.\S+/;
        temp.email = re.test(values.email) ? "" : t('error:emailInvalid');
        temp.message = values.message ? "" : t('error:fieldRequired');

        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x === "")
    }

    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
        setSuccessMsg('');
        if(validate()){
            setSubmitting(true);
            setValues({email: '', message: ''});
            api.contact(values).then((res) => {
   
                setSuccessMsg(t('error:sendMessageSuccess'));
                setSubmitting(false);
            }).catch((e) => {
                
                setFormError(t('error:sendMessageFailed'));
                setSubmitting(false);
            })
        }
    }
    return (
        <>
            <Helmet>
                <title>
                    {t('metaTitle')}
                
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Container maxWidth="xl" className={classes.container}>
                <Box my={4}>
                    <Typography variant="h1" align="center" gutterBottom>
                        {t('title')}
                    </Typography>
                </Box>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Form onSubmit={handleSubmit}>

                            {
                                formError &&
                                <Box my={3} p={1} bgcolor="secondary.light">
                                    <Typography variant="body1" color="error">
                                        {formError}
                                    </Typography>
                                </Box>
                            }
                            {
                                successMsg &&
                                <Box my={3} p={1} bgcolor="secondary.light">
                                    <Typography variant="body1" color="textPrimary">
                                        {successMsg}
                                    </Typography>
                                </Box>
                            }

                            <Input  
                                label={t('emailField')}
                                name="email"
                                value={values.email}
                                onChange= {handleInputChange}
                                error={errors.email}
                            />
                            <Input  
                                label={t('messageField')}
                                multiline
                                name="message"
                                value={values.message}
                                onChange= {handleInputChange}
                                error={errors.message}
                            />

                            <Box  mx={1} my={2} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <Button 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    type="submit"
                                    color="primary"
                                    endIcon={<ArrowForwardIcon />}
                                    className={classes.buttonRoot}
                                    disabled={submitting}
                                >
                                    <Typography variant="h5">
                                    {t('buttonText')}
                                    </Typography>
                                </Button>
                    
                            </Box>

                        </Form>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box px={1}>
                        <Box mb={3}>
                            <Typography variant="body1" gutterBottom>
                                <Trans i18nKey="contact:text1">
                                    If you have any questions about <b>Welder Competency and Credential Assessment</b>, please send us a message using the form. You can also call or email us using the information provided below. 
                                </Trans>
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <div>
                            <Link color="secondary" target="_top" href="mailto:weldercompetency@cwbgroup.org">weldercompetency@cwbgroup.org</Link>
                            </div>
                            <div>
                            <Link color="secondary" href="tel:+1800-844-6790">+1 800-844-6790</Link>
                            </div>
                        </Box>
                        <Box mb={3} display="flex" alignItems="center" justifyContent="flex-start">
                            
                           <IconButton target="_blank" href="https://www.facebook.com/cwbgroupwelding"> <img src={iconFacebook} alt="facebook icon" className={classes.icon}/></IconButton>
                           <IconButton target="_blank" href="https://www.linkedin.com/company/cwb-group/"><img src={iconLinkedin} alt="linkedIn icon" className={classes.icon}/></IconButton>
                           <IconButton target="_blank" href="https://twitter.com/cwbgroupwelding"><img src={iconTwitter} alt="twitter icon" className={classes.icon}/></IconButton>

                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
        
    );
};

export default Contact;