import React, {useLayoutEffect} from "react";
import { Typography,useMediaQuery, Box, Container, Grid, Button, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useLocation, useHistory } from "react-router-dom";
import {useAuth} from './useAuth';
import {useTranslation, Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    overlayContainer: {
        backgroundColor: 'black',
        position: 'fixed',
        opacity: '0.9',
        zIndex: 998,
        bottom: 0,
        width: '100%',
        padding: theme.spacing(1, 0),
    },
        overlayWrapper: {
        padding: theme.spacing(1, 4),
        [theme.breakpoints.down('sm')]: {
            padding: '0px 8px',
        }
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    closeIcon: {
      color: 'white',
      marginLeft: theme.spacing(1),
      cursor: 'pointer'
    },
}))

const BottomBar = () => {
    const classes = useStyles();
    const [overlay, setOverlay] = React.useState(true);
    const location = useLocation();
    const history = useHistory();
    const {user, token} = useAuth();
    const { t } = useTranslation('common');

    const closeOverlay = () => {
        setOverlay(!overlay);
    }

    const handleClick = (e) => {
        if(token) {
          history.push({
            pathname: '/start-assessment'
          })
        } else {
          history.push({
            pathname: '/signup',
            state: {background: {hash: "", pathname: location.pathname, search: "", state: undefined}, goTo: '/start-assessment' }
          })
        }
        
    }

    return(
        <div>
        {
          overlay ? 
          (<div className={`${classes.overlayContainer}`} >
            {/* <Container maxWidth="lg">
              <div className={classes.overlayWrapper}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{color: 'white'}}>
                      {t('floatingBanner.text1')}
                    </Typography>
                    <Typography variant="body1" color="primary" gutterBottom>
                      <Trans i18nKey="common:floatingBanner.text2">
                        Take the <b>FREE</b> assessment to find out.
                      </Trans>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} >
                    <Box display="flex"  >
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        className={classes.buttonRoot}
                        // component={Link}
                        onClick={handleClick}
                        // to={{
                        //   pathname:"/start-assessment",
                        //   // state: { background: {hash: "", pathname: "/start-assessment", search: "", state: undefined} }
                        //   // state: {background: location}
                          
                        // }}
                        >
                          <Typography variant="h5">
                          {t('buttons.startAssessment')}
                          </Typography>
                      </Button>
                      <CloseIcon fontSize="small" onClick={closeOverlay} className={classes.closeIcon}/>
                    </Box>

                  </Grid>

                </Grid>
                </div>
            </Container>       */}
          </div>):null
        }
        </div>
    )
}

export default BottomBar;