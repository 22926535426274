import React from 'react'
import { Grid, Typography, Box, Avatar, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import trainingImage2 from '../../assets/training/training-image2.png';
import trainingImage3 from '../../assets/training/training-image3.png';
import iconCertif from '../../assets/icons/icon-certif.png';
import { Link as ScrollLink } from 'react-scroll';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    sectionImage: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'350px',
        margin: '0 auto',
        borderRadius: '1rem',
    },
    link: {
        textDecoration: 'none',
    },
    avatar: {
        height: '80px',
        width: '80px',
        margin: theme.spacing(1, 'auto'),
        [theme.breakpoints.down('sm')]: {
            height: '50px',
            width: '50px',
            flexBasis: '250px'
        },
    },
    paddingSmall: {
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },
    imageSection: {
        [theme.breakpoints.down('sm')]:{
          flexDirection: 'column-reverse',
        }
      },
      
    imageContainer: {
        width: '100%',
    },
    // image: {
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     height: '300px',
    //     width:'100%',
    //      margin: '0 auto',
    //     borderRadius: '1rem',
    //     [theme.breakpoints.down('sm')]: {
    //      borderRadius: 0
    //     },
    // },
    bodyImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '400px',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin:'0 auto',
    },
}));


const EducationSection = () => {
    const classes = useStyles();
    const { t } = useTranslation('weldingTraining');
    return (
        <>
        <div className={classes.paddingSmall}>
            <Box mb={3}>
                <Typography variant="h2" align="left" gutterBottom>
                    {t('section2.header')}
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography variant="h3" align="left" color="secondary" gutterBottom>
                    {t('section2.graphicTitle')}
                </Typography>
            </Box>
            <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={12}>                   
                    <Grid container spacing={4}  justify="center" alignItems="flex-start">

                        <Grid item className={classes.section2CircleWrapper} xs={12} md={4} > 
                            <Avatar alt={t('section2.timelineIcontAlt')} src={iconCertif} className={classes.avatar} ></Avatar>
                            <Typography variant="body2" color="inherit" paragraph  align="center" className={classes.circleText}>
                                <b>{t('section2.graphicText1')}</b>
                            </Typography>
                        </Grid>
                        <Grid item className={classes.section2CircleWrapper} xs={12} md={4}>
                            <Avatar alt={t('section2.timelineIcontAlt')} src={iconCertif} className={classes.avatar} ></Avatar>
                            <Typography variant="body2" color="inherit" paragraph align="center" className={classes.circleText}>
                                
                                <b>{t('section2.graphicText2')}</b>
                                
                            </Typography>
                        </Grid>
                        <Grid item className={classes.section2CircleWrapper} xs={12} md={4}>
                            <Avatar alt={t('section2.timelineIcontAlt')} src={iconCertif} className={classes.avatar} ></Avatar>
                            <Typography variant="body2" color="inherit" paragraph align="center" className={classes.circleText}>
                                <b>{t('section2.graphicText3')}</b>
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="left" gutterBottom>
                        {t('section2.text1')}  
                    </Typography>
                    <Box mb={3}>
                    <Typography variant="body1" align="left" gutterBottom>
                        {t('section2.text2')} 
                    </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {/* <div className={classes.sectionImage }  style={{backgroundImage: `url(/images/weldingTraining/training-image2.png)`}}></div> */}
                            <img src={trainingImage2} alt={t('section2.imageAlt1')} className={classes.bodyImage} />
                            
                                <Box my={2}>
                                    <Typography variant="body1" align="left">
                                        {t('section2.imgText1')} 
                                    </Typography>
                                </Box>
                                <Box my={4}>
                                    <Button
                                    color="primary" 
                                    size="small"
                                    variant="contained" 
                                    disableElevation
                                    className={classes.buttonRoot}
                                    component={ScrollLink} 
                                    to="School"
                                    >
                                        <Typography variant="h5">
                                            {t('section2.imgButton1')} 
                                        </Typography>
                                    </Button>
                                </Box>
                                
                            
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <div className={classes.sectionImage } style={{backgroundImage: `url(/images/weldingTraining/training-image3.png)`}}></div> */}
                            <img src={trainingImage3} alt={t('section2.imageAlt2')} className={classes.bodyImage} />
                            
                                <Box my={2}>
                                    <Typography variant="body1" align="left">
                                        {t('section2.imgText2')} 
                                    </Typography>
                                </Box>
                                <Box my={4}>
                                    <Button
                                    color="primary" 
                                    size="small"
                                    variant="contained" 
                                    disableElevation
                                    className={classes.buttonRoot}
                                    component={ScrollLink} 
                                    to="Credentials"
                                    >
                                        <Typography variant="h5">
                                            {t('section2.imgButton2')}
                                        </Typography>
                                    </Button>
                                </Box>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        </>
    )
}

export default EducationSection;
