import React, {useState} from 'react'
import { NavLink as Link, useLocation } from "react-router-dom";
import { 
    IconButton, 
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
    Button 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from '@material-ui/icons/Home';
import {useAuth} from '../useAuth';
import iconCwbGroup from '../../assets/icons/icon-cwb-group.png';
import iconMenuSearch from '../../assets/icons/icon-menu-search.png';
import iconArrowDown from '../../assets/icons/icon-arrow-down.png';
import iconArrowUp from '../../assets/icons/icon-arrow-up.png';
import {useTranslation} from "react-i18next";
import LanguageSelect from './LanguageSelect';

const MobileMenu = (props) => {
    let location = useLocation();
    const classes = useStyles();
    const {setOpenPopup, language, languageOptions, handleLanguageChange} = props;
    const [showNavItems, handleShowNavItems] = useState(false);
    const [expandWelder, setExpandWelder] = useState(false);
    const [expandCareer, setexpandCareer] = useState(false);
    const handleOnClick = () => handleShowNavItems(!showNavItems);
    const handleClose = () => handleShowNavItems(false);
    const {user, signout, token} = useAuth();
    const { t } = useTranslation('common');

    return (
        <div className={classes.root}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleOnClick}
            >
                <MenuIcon />
            </IconButton>
            <div style={{display:'flex', flexGrow: '1', justifyContent: 'center'}}>
                <Link  to="/" onClick={() => handleClose()} style={{marginRight: '16px'}}> 
                    <img className={classes.logo} src={iconCwbGroup} alt="CWB Logo"/>
                </Link>
            </div>

                {/* <img src={iconMenuSearch} alt="Search Icon" className={classes.itemMargin}/> */}
                </Box>
            
            {
                showNavItems && 
                <div className={classes.menu}>
                    
                    <Box className={classes.linksWrapper} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                        
                        <List className={classes.list}>
                            <ListItem  button component={Link} to="/" onClick={() => handleClose()} className={classes.listItem}>
                                <HomeIcon fontSize="large"/>
                            </ListItem>

                            <ListItem  button component={Link} to="/welder-competency-credential-assessment-program-information" onClick={() => handleClose()} className={classes.listItem}>
                                <ListItemText primary={t('topMenu.header1.title')} className={`${classes.listItemText}`} />
                            </ListItem>

                            <ListItem button alignItems="center" onClick={() => setExpandWelder(!expandWelder)} justifyContent="center" className={classes.listItem}>
                                <ListItemText primary={t('topMenu.header2.title')} className={`${classes.listItemText}`}/>
                                {expandWelder ? <IconButton size="small"><img className={classes.iconArrow} src={iconArrowUp}/></IconButton> :<IconButton size="small"><img className={classes.iconArrow} src={iconArrowDown}/></IconButton>}
                            </ListItem>
                            <Collapse in={expandWelder} unmountOnExit className={classes.collapse}>
                                <List component="div" disablePadding className={classes.expandedList}>
                                    <ListItem button className={classes.nested} component={Link} to="/international-welder-requirements-work-canada" onClick={() => handleClose()} className={classes.expandedListItem}>
                                        <ListItemText primary= {t('topMenu.header2.subHeader1')} className={`${classes.listItemText}`}/>
                                    </ListItem>

                                    <ListItem button className={classes.nested} component={Link} to="/welder-requirements-across-canada" onClick={() => handleClose()} className={classes.expandedListItem}>
                                        <ListItemText primary={t('topMenu.header2.subHeader2')} className={`${classes.listItemText}`}/>
                                    </ListItem>

                                    <ListItem button className={classes.nested} component={Link} to="/welding-training-canada" onClick={() => handleClose()} className={classes.expandedListItem}>
                                        <ListItemText primary={t('topMenu.header2.subHeader3')} className={`${classes.listItemText}`}/>
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem  button component={Link} to="/welders-certifications-requirements-canada" onClick={() => handleClose()} className={classes.listItem}>
                                <ListItemText primary={t('topMenu.header3.title')} className={`${classes.listItemText}`}/>
                            </ListItem>

                            <ListItem  button component={Link} to="/welder-competency-credential-assessment-information" onClick={() => handleClose()} className={classes.listItem}>
                                <ListItemText primary={t('topMenu.header4.title')} className={`${classes.listItemText}`}/>
                            </ListItem>



                            <ListItem button alignItems="center" onClick={() => setexpandCareer(!expandCareer)} justifyContent="center" className={classes.listItem}>
                                <ListItemText primary={t('topMenu.header5.title')} className={`${classes.listItemText}`}/>
                                {expandCareer ? <IconButton size="small"><img className={classes.iconArrow} src={iconArrowUp}/></IconButton> :<IconButton size="small"><img className={classes.iconArrow} src={iconArrowDown}/></IconButton>}
                            </ListItem>
                            <Collapse in={expandCareer} unmountOnExit className={classes.collapse}>
                                <List component="div" disablePadding className={classes.expandedList}>
                                    <ListItem button className={classes.nested} component={Link} to="/search-welding-jobs-canada" onClick={() => handleClose()} className={classes.expandedListItem}>
                                        <ListItemText primary={t('topMenu.header5.subHeader1')} className={`${classes.listItemText}`}/>
                                    </ListItem>
                                    
                                    {/** DISABLED HIRE A WELDER */}
                                    {/* <ListItem button className={classes.nested} component={Link} to="/hireWelder" onClick={() => handleClose()} className={classes.expandedListItem}>
                                        <ListItemText primary="Hire a Welder"/>
                                    </ListItem> */}
                                </List>
                            </Collapse>

                            
                            <ListItem onClick={() => handleClose()} className={classes.listItem}>
                                {
                                    token ? (
                                        <Button 
                                            color="primary" 
                                            variant="contained" 
                                            disableElevation 
                                            className={classes.buttonRoot}
                                            onClick={() => signout()}
                                        >
                                            <b>{t('topMenu.logoutBtn')}</b>
                                        </Button>

                                    ) : (
                                    <Button 
                                        color="primary" 
                                        variant="contained" 
                                        disableElevation 
                                        className={classes.buttonRoot}
                                        // onClick={() => setOpenPopup(true)}
                                        component={Link}
                                        to={{
                                            pathname:"/signup",
                                            state: { background: location }
                                        }}
                                    >
                                        <b>{t('topMenu.signupBtn')}</b>
                                    </Button>
                                    )
                                }
                                
                            </ListItem>

                            <ListItem className={classes.listItem}>
                                <LanguageSelect 
                                    language={language}
                                    handleLanguageChange={handleLanguageChange}
                                    languageOptions={languageOptions}
                                />
                            </ListItem>

                        </List>
                    </Box>
                </div>
            }
        </div>
    )
}
const useStyles = makeStyles((theme) => ({
    collapse: {
        width: '100%'
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    listItem: {
        width: 'auto',
        padding: theme.spacing(2, 0)
    },
    listItemText: {
        '& span' :{
            fontSize: '24px',
            fontWeight: 'bold'
        }
    },
    expandedList: {
        display:'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    expandedListItem: {
        width: 'auto',
        padding: theme.spacing(1, 0)
    },
    menu: {
        backgroundColor: '#F2F2F2',
        position: 'absolute',
        width: '100%',
        padding: '15px',
        overflow: 'auto',
        height: '100vh',
        top: '100%',
        left:'0',
        zIndex: 999,
        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
    },
    root: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: "white",
        color: "black"
    },
    menuButton: {
    //   marginRight: theme.spacing(2),
      '&:focus': {
          outline: 'none'
      }
    },
    title: {
      flexGrow: 1,
    },
    link: {
      color: "black",
      textDecoration: "none",
      margin: theme.spacing(2, 1.5),
      padding: theme.spacing(2, 2),
      '&:hover': {
          textDecoration: 'none',
          color: 'black'
      }
    },
    activeLink: {
      borderBottom: '0.4rem solid black',
    },
    logo: {
        height: '50px',
        width:'50px'
    },
    iconArrow: {
        width: '20px',
        height: '20px'
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 8px',
        margin: theme.spacing(0, 1.5),
        fontSize: '24px',
    },
    language: {
        color: theme.palette.secondary.main,
        fontSize: '20px',
    },
  }));
export default MobileMenu
