import React, {useEffect, useState} from "react";
import {Button, Typography, Box} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        margin: '4px',
        padding: '4px 24px',
        position: 'fixed',
        bottom: '100px',
        right: '20px',
        cursor: 'pointer',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100px',
        zIndex: '999',

    }
}))
export default function ScrollTopButton () {
    const [isVisible, setIsVisible] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation('common');
    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div >
            {isVisible &&
                <div className={classes.root} onClick={scrollToTop}>
                    <ArrowUpwardIcon />
                    <Typography variant="h5" align="center">{t('floatingButton')}</Typography>
                </div>
            }
        </div>
    )

}