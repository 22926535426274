import React from 'react'
import { 
    makeStyles,
    Grid,
    Box
} from '@material-ui/core';
import ArrowIcon from './ArrowIcon';

const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        display:'flex',
        alignItems: 'center',
        justifyContent:'center',
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column'
        }
    }
}));
const ArrowBox = ({children, mdCols=3, noArrow }) => {
    const classes = useStyles();
    
    return (
        <Grid item xs={12} lg={`${mdCols}`} >
            <div className={classes.boxWrapper}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    {children}
                </Box>
                {
                    !noArrow ?   <ArrowIcon /> : null 
                }
                
            </div>
        </Grid>
    );
}

export default ArrowBox
