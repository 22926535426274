import React from 'react';
import { 
    List,
    makeStyles,
    withStyles
} from "@material-ui/core";
import { Link } from 'react-scroll';
import MuiListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
    container: {
         margin: '0 auto',
    },
    list: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',    
    },
    root: {
        marginBottom:theme.spacing(3),
        '& a.active .MuiListItem-root ': {
            backgroundColor: theme.palette.secondary.main
        },
        '& a:not(.active) .MuiListItem-root ': {
            backgroundColor: '#FFF',
        },

    },
    

}));

const ListItem = withStyles((theme)=>({
    root: {
        height: '35px',
        width: '35px',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',        
        backgroundColor: '#FFF',
        margin: theme.spacing(0,1),
        "&$selected": {
            backgroundColor: theme.palette.secondary.main
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.secondary.light,
        }, 
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
    }

}))(MuiListItem);

const ScrollMenu = ({sections, selectedIndex, setSelectedIndex, setMapType}) => {
    const classes = useStyles();

    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
      if(setMapType) {
        setMapType(sections[index].id);
      }
    };
  
    return (
      <div className={classes.container}>   
        <List component="nav" className={`${classes.list} ${classes.root}`}>
            {
                sections.map((section, idx) => {
                    return ( 
                        <Link
                            activeClass="active"
                            to={section.id}
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >
                            <ListItem 
                                button
                                selected={selectedIndex === idx}
                                onClick={(event) => handleListItemClick(event, idx)}
                                // classes={{root: classes.root, selected: classes.selected}}
                            >    
                        
                            </ListItem>
                        </Link>
                    )
                })
            }
        </List>
      </div>
    )
};

export default ScrollMenu;
  