import React, {useEffect} from "react";
import { 
    Grid, 
    Typography,
    Hidden, 
    Box, 
    Button,
    Container
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, Link } from "react-router-dom";
import {  useAuth } from '../../components/useAuth';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ScrollMenu from '../../components/ScrollMenu';
// import ScrollMenuMobile from '../../components/ScrollMenuMobile';
import Banner from '../../components/Banner';
import assessmentHeroDeskTop from '../../assets/assessment/assessment-header-desktop.png';
import assessmentHeroMobile from '../../assets/assessment/assessment-header-mobile.png';
import iconInternational from '../../assets/icons/9-Assessment.png';
import {Element} from 'react-scroll';
import ScrollTopButton from "../../components/ScrollTopButton";

import {Helmet} from "react-helmet";
import {fetchPastResultsPresent} from "../../api/quiz";
import {scroller } from 'react-scroll';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    section:{
        // height: '35vh',
      marginBottom: theme.spacing(12),
    //   paddingBottom: theme.spacing(12),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    sidebar: {
      position: 'sticky',
      top: '10px'
    },
    itemMargin: {
        marginBottom: theme.spacing(2)
    },
    padding: {
        // paddingRight: theme.spacing(8),
        // paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]:{
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        }
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin: theme.spacing(2, 0)
    },
    buttonContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column'
        }
    },
    bottomSection: {
        paddingBottom: '30%'
    },
    container: {
    
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          padding: 0,
          // justifyContent: 'center',
        }
    },
    tableContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        overflow:'hidden',
        backgroundColor: '#FFF',
        //paddingBottom: '12px',
    },
    table: {
        tableLayout: 'fixed',
        '& thead th': {
            wordWap: 'break-word',
            fontWeight: '600',
             color: theme.palette.secondary.main,
    
        },
        '& tbody td': {
            wordWrap: 'break-word',
            fontWeight: '300'
        },
        '& tbody tr:hover': {
       
        },
        '& .MuiTableCell-root':{
            borderBottom: 'none',
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    link: {
        color: theme.palette.secondary.main
    },
    
}));

const Assessment = (props) => {
    const classes = useStyles();
    const [selectedIndexMobile, setSelectedIndexMobile] = React.useState(0);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [pastResultsExists, setPastResultsExist] = React.useState(false);
    const history = useHistory();
    const { user, token } = useAuth();
    let location = useLocation();
    const { t } = useTranslation('assessment');

    const sections = [
        {id:'Overview', title: t('navButtons.overview')}, 
        {id:'Cost', title:t('navButtons.cost')}, 
        {id:'Prepare', title:t('navButtons.prepare')}, 
        {id:'TestAssessment', title: t('navButtons.practiceAssessment')},
        {id:'StartAssessment', title: t('navButtons.startAssessment')},
        // {id:'Resource', title:'Resource'}
    ];

    useEffect(() => {
        if(token) {
            fetchPastResultsPresent()
            .then((res) => {
                if(res.pastResult) {
                    setPastResultsExist(true);
                } else {
                    setPastResultsExist(false);
                }
            })
            .catch((e) => {
                console.log('error',e)
                setPastResultsExist(false);
            })
        }
    }, [token]);

    useEffect(()=>{
        if (location.state?.scrollToStartAssessment) {
            scroller.scrollTo('StartAssessment', {
                spy:true,
                smooth:true,
                offset: -50,
                duration: 500
            });
        }
    }, [])

    const handleStartAssessment = () => {
        if( token ) {
            history.push({
                pathname: '/start-assessment'
            })
        } else {
            history.push({
                pathname: '/signup',
                state: {background: {hash: "", pathname: location.pathname, search: "", state: undefined}, goTo: '/start-assessment' }
            })
        }
    }

    const handleTestAssessment = () => {
        if( token ) {
            history.push({
                pathname: '/test-assessment'
            })
        } else {
            history.push({
                pathname: '/signup',
                state: {background: {hash: "", pathname: location.pathname, search: "", state: undefined}, goTo: '/test-assessment' }
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    {t('metaTitle')}
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Banner 
                src={assessmentHeroMobile} 
                srcset={assessmentHeroDeskTop} 
                alt={''} 
                iconSrc={iconInternational} 
                iconAlt={t('bannerIcon')}
                title={t("banner")}
                jc={'center'}
            />
            <Container maxWidth="xl" className={classes.container}>
            <ScrollTopButton />
            <Grid container justify="center" spacing={2} className={`${classes.padding}`} style={{margin: 0,width: '100%',}}>
                <Hidden lgDown> 
                    <Grid item lg={2} > 
                        <div className={classes.sidebar}>
                         <ScrollMenu sections={sections} offset={-250}/>
                        </div>
                    </Grid>
                </Hidden>
               
                <Grid item xs={12} xl={10}>
                    <Element name="Overview">
                    {/**Section 1 */}
                    <section className={classes.section} id="Overview">
                        <Box>
                            <Typography variant="h2" gutterBottom align="left"> 
                                {t("section1.header")}                           
                            </Typography>
                            <Box mt={3}>
                                <Typography variant="body1" gutterBottom align="left">
                                    {t("section1.text")}
                                </Typography>
                            </Box>
                            {
                                t('section1.items', {returnObjects: true}).map((item, idx) =>
                                    <Box key={idx} my={3}>
                                        <Typography variant="h3" gutterBottom align="left" color="secondary">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom align="left">
                                            {item.body}
                                        </Typography>
                                        {   item.links.length > 0 &&
                                            item.links.map((link, index) =>
                                                <Link 
                                                    key={index}
                                                    className={classes.link} 
                                                    to={{ pathname:link}}
                                                    target="_blank" 
                                                >
                                                    <Typography variant="body1">
                                                        {link}
                                                    </Typography>
                                                </Link>
                                            )
                                        }
                                    </Box>
                                )
                            }

                            
                        </Box>
                    </section>
                    {/**End Section 1 */}
                    </Element>
                    <Element name="Cost">
                    {/**Section 2 */}
                        <section className={classes.section} id="Cost">
                            <Box pb={4} mb={8}>
                                <Typography variant="h2" gutterBottom align="left">
                                    {t("section2.header")}
                                </Typography>
                                <Typography variant="body1" align="left" gutterBottom>
                                    {t("section2.text")}  
                                </Typography>
                            </Box>
                        </section>
                    {/**End Section 2 */}
                    </Element>

                    <Element name="Prepare">
                    
                    <section className={classes.section} id="Prepare">
                        <Box pb={4} mb={8}>
                            <Typography variant="h2" gutterBottom align="left">
                                {t("section3.header")}  
                            </Typography>
                            <Typography variant="body1" align="left" gutterBottom>
                                {t("section3.text1")}      
                            </Typography>
                            <Typography variant="body1" align="left" gutterBottom>
                                {t("section3.text2")}  
                            </Typography>
                            <Box mt={2}>
                            <Link 
                                className={classes.link} 
                                to={{
                                    pathname: "/welders-certifications-requirements-canada",
                                    state: { scrollToMap: true }
                                }}>
                                    <Typography variant="body1">
                                        {t("section3.linkText1")}  
                                    </Typography>
                            </Link>
                            </Box>
                            <Box mt={2}>
                                <Link 
                                    className={classes.link} 
                                    to={{ pathname:"https://www.cwbgroup.org/education/online-courses"}}
                                    target="_blank" 
                                    >
                                        <Typography variant="body1">
                                        {t("section3.linkText2")} 
                                        </Typography>
                                </Link>
                            </Box>

                        </Box>
                    </section>
                    </Element>
                    {/**End Section 3 */}

                    {/**Section 4 */}
                    {/* <Element name="TestAssessment">
                        <section className={classes.section}>
                        <Box pb={4} mb={8}>
                            <Typography variant="h2" gutterBottom align="left">
                                {t("section4.header")}  
                            </Typography>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2} borderRadius={16} bgcolor="secondary.light" height={300}>
                                
                                <Typography variant="h3" align="center" color="secondary" gutterBottom>
                                    <b>
                                        {t("section4.text1")}  
                                    </b>
                                </Typography>
                                <Button 
                                    color="primary" 
                                    size="small" 
                                    variant="contained" 
                                    target="_blank" 
                                    href={""} 
                                    disableElevation
                                    className={classes.buttonRoot}
                                    onClick={handleTestAssessment}
                                >
                                     <Typography variant="h5">
                                     {t("section4.buttonText")}  
                                    </Typography>
                                </Button>
                                {
                                    !token && 
                                    <Typography variant="h4" align="center" color="secondary" gutterBottom>
                                        {t("section4.text2")}
                                    </Typography>
                                }
                            </Box>
                        </Box>
                        </section>
                    </Element>
                     */}

                    {/**Section 5 */}
                    {/* <Element name="StartAssessment">
                        <section className={`${classes.section} ${classes.bottomSection}`}>
                        <Box pb={4} mb={8}>
                            <Typography variant="h2" gutterBottom align="left">
                                {t("section5.header")}  
                            </Typography>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2} borderRadius={16} bgcolor="secondary.light" height={300}>
                                
                                <Typography variant="h3" align="center" color="secondary" gutterBottom>
                                    <b>
                                    {t("section5.text1")}
                                    </b>
                                </Typography>
                                <Box display="flex" justifyContent="space-around" alignItems="center" className={classes.buttonContainer}>
                                    <Box mx={1}>
                                        <Button
                                            endIcon={<ArrowForwardIcon />} 
                                            color="primary" 
                                            size="large"
                                            variant="contained" 
                                            disableElevation
                                            onClick={handleStartAssessment}
                                            className={classes.buttonRoot}
                                            // component={Link} 
                                            // to="/programInfo"
                                            
                                        >
                                            <Typography variant="h5">
                                            {t("section5.buttonText1")}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    {
                                        token && pastResultsExists &&
                                        <Box mx={1}>
                                        <Button
                                            color="primary" 
                                            size="large"
                                            variant="contained" 
                                            disableElevation
                                            onClick={handleStartAssessment}
                                            className={classes.buttonRoot}
                                            component={Link} 
                                            to="/result"
                                        
                                    >
                                        <Typography variant="h5">
                                        {t("section5.buttonText2")}
                                        </Typography>
                                    </Button>
                                    </Box>
                                }
                                </Box>
                                {
                                    !token && 
                                    <Typography variant="h4" align="center" color="secondary" gutterBottom>
                                        {t("section5.text2")}
                                    </Typography>
                                }
                                
                            </Box>
                        </Box>
                        </section>
                    </Element> */}
                    {/* 
                    <Element name="Resource">
                    
                    <section className={classes.section} id="Resource">

                        <Box pb={4} mb={8}>
                            <Typography variant="h3" gutterBottom align="left">
                              
                                    Resource
                              
                            </Typography>
                            <Box my={3}>

                                <Box my={3}>
                                    <Typography variant="h4" gutterBottom>
                                        <b>WCCA Program - Mapping</b>
                                    </Typography>
                                </Box>
                                <div className={classes.tableContainer}>
                                    <Table className={classes.table} >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    <Typography variant="subtitle2">
                                                        <b>Course Name</b>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="subtitle2">
                                                        <b>Course Code</b>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rows.map((row, i) => (
                                            <>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography variant="body2" color="secondary">
                                                        <b>{row.title}</b>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    row.items.map((item, idx) =>(
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                <Typography variant="body2">
                                                                {item.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="body2">
                                                                {item.code}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>    
                                                    ))
                                                }
                                             
                                            </>
                                        ))}
                                        </TableBody>

                                    </Table>
                                </div>
                     
                            </Box>
                                <Button 
                                    color="primary" 
                                    size="small" 
                                    variant="contained" 
                                    target="_blank" 
                                    href={""} 
                                    disableElevation
                                    className={classes.buttonRoot}
                                    onClick={handleTestAssessment}
                                >
                                    Click to take example assessment
                                </Button>
                        </Box>
                    </section>
                    
                    </Element> */}
                    {/**End Section 4 */}
                </Grid>
        
            </Grid>
            </Container>
            {/* <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <SampleQuestions/>
            </Popup> */}
        </>
        
    );
};

export default Assessment;