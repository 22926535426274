import React from 'react'
import { makeStyles, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import iconClose from '../assets/icons/icon-close.png';
import {useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        // padding: theme.spacing(2),
        // position: 'absolute',
        // top: theme.spacing(5),
    },
    dialogRoot: {
        // backgroundColor: '#E8E8E8'
    },
    rounded :{
        borderRadius: '1rem'
    },
    icon: {
        height: '30px',
        width: '30px'
    },
    dialogContent: {
        padding: theme.spacing(3)
    },
    dialogTitle: {
        paddingBottom:0
    }

}));

export default function Popup(props) {
    const classes = useStyles();
    const {children, openPopup, setOpenPopup} = props;
    let history = useHistory();
    let location = useLocation();
    const handleClose = (e) => {
        e.stopPropagation();
        if(location.state?.background?.pathname){
            history.push(location.state?.background?.pathname)
        } else {
            //history.goBack();
            setOpenPopup(false)
        }
    }
    return (
        <Dialog open={openPopup} maxWidth="md" classes={{ paper: classes.dialogWrapper, root: classes.dialogRoot}}>
            <DialogTitle classes={{root: classes.dialogTitle}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton
                        onClick={handleClose}
                    > 
                        <img src={iconClose} className={classes.icon}/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{root: classes.dialogContent}}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
