import React from 'react'
import { makeStyles } from '@material-ui/core'

export function useForm(initialValues) {
    const [values, setValues] = React.useState(initialValues);
    const [errors, setErrors] = React.useState({});

    const handleInputChange = e => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name] : value
        })
    }

    const resetForm = () => {
        setValues(initialValues);
        setErrors({});
    }
    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            // width: '100%',
            margin: theme.spacing(2,1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
       
    }
}));
export function Form(props) {

    const classes = useStyles();
    const {children, ...other} = props;


    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}
