import Select from '../../components/Select';

const LanguageSelect = ({language, handleLanguageChange, languageOptions}) => {
    return (
        <Select
            name="selectLanguage"
            value={language}
            onChange= {handleLanguageChange}
            options={languageOptions}
            hideNoneOption={true}
        />
    )
}

export default LanguageSelect