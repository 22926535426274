import React from 'react'
import { 
    Typography,Box,Button,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useTranslation} from "react-i18next";

const YT = ({data}) => {
    const {downloadLink, text1, text2, section1, downloadText} = data;
    const { t } = useTranslation('regulation');
    return (
        <>
            <Box my={3}>
                <Box mb={3}>
                    <Typography variant="body1" align="left" gutterBottom>
                    {text1}
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                    {text2}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Box mb={2}>
                        <Typography variant="body1" align="left" gutterBottom>
                            <b>{section1.title}</b>
                        </Typography>
                        <Typography variant="body1" align="left" gutterBottom>
                                {section1.listHeader1}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <ul>
                            {
                                section1.list1.map((item, idx) => (
                                    <li key={idx}>
                                        <Typography variant="body1" gutterBottom>
                                            {item}
                                        </Typography>
                                    </li>
                                ))
                            }
                        </ul>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="body1" align="left" gutterBottom>
                        {section1.listHeader2}
                        </Typography>
                        <Box mb={2}>
                            <ul>
                            {
                                section1.list2.map((item, idx) => (
                                    <li key={idx}>
                                        <Typography variant="body1" gutterBottom>
                                            {item}
                                        </Typography>
                                    </li>
                                ))
                            }
                            </ul>
                        </Box>
                    </Box>


                </Box>
            </Box>
            <Box>
                <Typography variant="body1" gutterBottom>
                    {downloadText}
                </Typography>
                <Button
                    startIcon={<GetAppIcon />} 
                    color="primary" 
                    size="large"
                    variant="contained" 
                    disableElevation
                    href={downloadLink}
                    target="_blank"
                >
                    <Typography variant="h5">
                    {t('section6.downloadText')}
                    </Typography>
                </Button>
            </Box>
        </>
    )
}

export default YT
