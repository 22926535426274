import React, { useState, useEffect, useContext, createContext } from "react";
import * as api from '../api/account';
import {setAuthInfo, removeAuthInfo} from '../api/base';

const dummyUser = {
    id: 4,
    username: 'bob',
    email: 'bob@bob.com'
};

export const authContext = createContext();

export function ProvideAuth({ children }) {
    
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    
    const signin = (email, password) => {

        return api.login({email, password}).then(response => {
            setAuthInfo(response.token);
            setToken(response.token);
            setUser(response.user);
            return response
        });
        
        //  setUser(dummyUser);
        //  return dummyUser;
   
    };
  
    const signup = (email, password, country, agree, qualification, optInEmail) => {
        return api.signup({email, password, country, agree, qualification, optInEmail}).then(response =>{ 
            
            
        });
        
        //  setUser(dummyUser);
        //  return dummyUser;

    };
  
    const signout = () => {
        return new Promise((resolve) => {
            removeAuthInfo();
            setUser(null);
            setToken(null);

            resolve();
        }); 
        // setUser(false);

    };

    const getCurrentUser = (token) => {
        return api.getCurrentUser().then(response => {
            setUser(response);
            
        }).catch((e) => {
            
            signout();
        })
    }
    
    useEffect(() => {
        let savedToken = window.localStorage.getItem('cwbJwt');
        if (savedToken) {
            setAuthInfo(savedToken)
            getCurrentUser();
            setToken(savedToken)
        }
       
    }, [token]);
  
    return {
        getCurrentUser,
        setToken,
        token,
        user,
        signup,
        signin,
        signout
    };
}