import React, {useState} from  'react'
import { makeStyles, Grid, Typography, Box, Button, useMediaQuery, TextField} from "@material-ui/core";
import {useForm, Form} from '../../components/useForm';
import Input from '../../components/Input';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from '../../components/useAuth';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useTranslation, Trans} from "react-i18next";


const initialValues = {
    email: '',
    password: ''
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '80%',
    },
    link: {
        // textDecoration: 'none',
        color: theme.palette.secondary.main
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}));
const LoginForm = (props) => {
    const classes = useStyles();
    const {setOpenPopup} = props;
    const {signin} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    let location = useLocation();
    let history = useHistory();
    const { t } = useTranslation(['common', 'error']);

    const validate = () => {
        let temp = {}
        const re = /\S+@\S+\.\S+/
        temp.email = re.test(values.email) ? "" : t('error:emailInvalid')
        
        if (values.password.length !== 0) {
            if(values.password.length > 5) {
                temp.password = ""
            } else {
                temp.password = t('error:minCharacters')
            }
        } else {
            temp.password = t('error:fieldRequired')
        }

        setErrors({
            ...temp
        })


        var tempError = Object.keys(temp).map(function(key){
            return temp[key]
        });
        //return Object.values(temp).every(x => x === "")
        return tempError.every(x => x === "")
    }

    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
        if(validate()){
            setSubmitting(true);
            signin(values.email, values.password)
                .then(() => {
                    setSubmitting(false);
                    if(location.state?.goTo){
                        history.push({
                            pathname:location.state.goTo,
                            // state: {}
                        });
                    } else {
                        history.push({
                            pathname:location.state.background.pathname,
                            
                        });
                    }
                   

                })
                .catch(err => {
                    setFormError(t('error:invalidEmailPassword'));
                    setSubmitting(false);

                });
            // setOpenPopup(false);
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                
                {
                    formError &&
                    <Box my={3} p={1} bgcolor="secondary.light">
                        <Typography variant="subtitle2" color="error">
                            {formError}
                        </Typography>
                    </Box>
                }

                <Input  
                    label={t('loginForm.fields.email')}
                    name="email"
                    value={values.email}
                    onChange= {handleInputChange}
                    error={errors.email}
                />

                <Input
                    label={t('loginForm.fields.password')}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange= {handleInputChange}
                    error={errors.password}
                />
                <Box mx={1} my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Button 
                        color="primary" 
                        size="large" 
                        variant="contained" 
                        disableElevation
                        className={classes.buttonRoot}
                        endIcon={<ArrowForwardIcon />}
                        type="submit"
                        disabled={submitting}
                    >
                        {t('loginForm.loginButton')}
                    </Button>

                    <Box my={2} display="flex" flexDirection="row" justifyContent="space-between">
                            <Box mr={1}>

                                <Typography variant="subtitle2">
                                {t('loginForm.forgotPassword')}
                                </Typography>
                            </Box>
                            <Link className={classes.link} to="/reset">
                                <Typography  variant="subtitle2">
                                    <b>{t('loginForm.clickHere')}</b>
                                </Typography>
                            </Link>
                    </Box>
                </Box>
            </Form>
        </div>
    )
}

export default LoginForm
