import React from "react";
import { Typography, Container, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link} from "react-router-dom";
import iconFcr from '../assets/icons/icon-fcr.png';
import iconCwb from '../assets/icons/icon-cwb.png';
import goc from '../assets/icons/govOfCanada.png';
import {useTranslation, Trans} from "react-i18next";
const footers = [
    {
        title: 'About CWB',
        link: '/about-cwb-welders-canada'
    },
    {
        title: 'Contact Us',
        link: '/contact-us'
    },
    {
        title: 'FAQ',
        link: '/welders-work-canada-faq'
    },
    {
        title: 'Privacy Policy',
        link: '/privacy'
    },
    {
        title: 'Terms & Conditions',
        link: '/terms-and-conditions'
    }
];

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    return(
        <>
            <div className={classes.footerContainer}>
            <div className={classes.footerLinksWrapper}>
                <Container maxWidth="xl" component="footer" className={classes.footer}>
                    {footers.map((footer, idx) => (
                        
                        <Link to={footer.link} className={classes.link} key={footer.title}>
                            <Typography variant="h5" color="textSecondary">
                                {t(`footer.title${idx+1}`)}
                            </Typography>
                        </Link>
                      
                    ))}
                </Container>
            </div>
            <Container maxWidth="xl">
            <Grid container spacing={2} justify="center" alignItems="center" style={{margin: 0,width: '100%',}} >
                <Grid item xs={12} lg={4} md={6}>
                    <img src={iconCwb} alt={t('footer.icontAlt1')} className={classes.footerLogo}></img>
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                    <img src={iconFcr} alt={t('footer.iconAlt2')} className={classes.footerLogo}></img>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" className={classes.footerLogo}>
                        <Typography variant="body2" paragraph>
                            <b>{t('footer.fundedBy')}</b>
                        </Typography>
                        <img src={goc} alt={t('footer.iconAlt3')} style={{maxWidth: '280px'}}></img>
                    </Box>
                </Grid>
            </Grid>
            </Container>

            {/* <Box my={4} display="flex" justifyContent="center" alignItems="center" className={classes.logoContainer}>
                <img src={iconCwb} alt="cwb logo" className={classes.footerLogo}></img>
                <img src={iconFcr} alt="FCR logo" className={classes.footerLogo}></img>
            </Box>
            <Box my={4} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" paragraph>
                <b>Funded By:</b>
                </Typography>
                <img src={goc} alt="government of canada" style={{maxWidth: '280px'}}></img>
            </Box> */}
            </div>
        </>

    );
};

const useStyles = makeStyles((theme) => ({
    footerLinksWrapper: {
        backgroundColor: theme.palette.secondary.main,
    },
    footer: {
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderTop: `1px solid ${theme.palette.divider}`,
        // marginTop: theme.spacing(8),
        // paddingTop: theme.spacing(3),
        // paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
        //   paddingTop: theme.spacing(6),
        //   paddingBottom: theme.spacing(6),
        },
    },
    footerContainer: {
        padding: theme.spacing(8, 0),
        marginBottom: theme.spacing(8),
    },
    link: {
        display:'flex',
        textDecoration: "none",
        margin: theme.spacing(1, 0),
        padding:theme.spacing(0, 2),
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            color: 'white'
        },
        borderRight: `2px solid ${theme.palette.primary.main}`,
        '&:last-child': {
            borderRight: 'none'
        }
    },
    footerLogo: {
        height: '75px',
        margin: theme.spacing(2),
        maxWidth: '100%',
    },
    logoContainer: {
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column'
        }
    }
}));

export default Footer;