import React from 'react'
import { 
    makeStyles,
    Container
} from '@material-ui/core';
import {getProvinceFromId} from './data';
import useTable from '../../components/useTable';

import AB from './pathways/AB';
import BC from './pathways/BC';
import MB from './pathways/MB';
import NB from './pathways/NB';
import NL from './pathways/NL';
import NS from './pathways/NS';
import NT from './pathways/NT';
import NU from './pathways/NU';
import ON from './pathways/ON';
import PE from './pathways/PE';
import QC from './pathways/QC';
import SK from './pathways/SK';
import YT from './pathways/YT';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

const displayProvince = (province) => {
    switch(province) {
        case 'CA-AB':
            return <AB />
        case 'CA-BC':
            return <BC />
        case 'CA-MB':
            return <MB />
        case 'CA-NB':
            return <NB />
        case 'CA-NL':
            return <NL />
        case 'CA-NS':
            return <NS />
        case 'CA-NT':
            return <NT />
        case 'CA-NU':
            return <NU />
        case 'CA-ON':
            return <ON />
        case 'CA-PE':
            return <PE />
        case 'CA-QC':
            return <QC />
        case 'CA-SK':
            return <SK />
        case 'CA-YT':
            return <YT />
        default: 
            return <ON />
    }
}

const CofQ = ({province}) => {
    const provinceComponent = displayProvince(province);
    return (
        <>
            <Container maxWidth="xl">
            {
                
                provinceComponent
                
            
            }
            </Container>
        </>
    );
}

export default CofQ;