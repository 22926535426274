import React, {useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import { 
    Typography,
    Box,
    Table, 
    TableRow,
    TableCell,
    TableBody,
    Container,
    Button,
    Grid,
    Paper,
    TableHead,
    CircularProgress
 } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import image from '../assets/result/result-image.png';
import ListSection from '../components/ListSection';
import iconCWB from '../assets/icons/icon-cwb-group.png';
import iconResult from '../assets/icons/4-program.png';
import {renderText} from '../components/renderText';

import {fetchQuizResults, fetchUserQuizId} from '../api';
import {useAuth} from '../components/useAuth';
import domToPdf from 'dom-to-pdf';
import {useTranslation, Trans} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    section:{
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    bottomSection: {
        backgroundColor: '#E8EDEF',
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'center'
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        height: '300px',
      },
    link: {
        color: theme.palette.secondary.main
    },
    tableContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        overflow:'hidden',
        backgroundColor: '#FFF',
    },
    table: {
        tableLayout: 'fixed',
        '& thead th': {
            wordWap: 'break-word',
            fontWeight: '600',
             color: theme.palette.secondary.main,
    
        },
        '& tbody td': {
            wordWrap: 'break-word',
            fontWeight: '300'
        },
        '& tbody tr:hover': {
       
        },
        '& .MuiTableCell-root':{
            borderBottom: 'none',
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },

}));

const useRecommendationsStyles = makeStyles((theme) => ({
    listSection: {
        '& li:first-child': {
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            // borderBottom: 1,
            // borderBottomStyle: 'solid',
            //  borderBottomColor: theme.palette.secondary.main   
        },
        '& li:nth-last-child(1)': {
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            borderBottom: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.secondary.main   
        },
        [theme.breakpoints.down('sm')]: {
            '& li:first-child': {
                border: 'none',
            },
            '& li:nth-last-child(1)': {
                border: 'none',
            },
            
        }
    }
}));
const useRecommendationItemStyles = makeStyles((theme) => ({
    listItem: {
        listStyle: 'none',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: 0,
        padding: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            borderRadius: '1rem',
            border: 'none',
            backgroundColor: '#E8EDEF',
            marginBottom: theme.spacing(3),
        }
    },
    link: {
        color: theme.palette.secondary.main
    },
}));

const RecommendationItem = ({item}) => {
    const classes = useRecommendationItemStyles();
    const { t } = useTranslation('result');
    const recommendations = [
        {
            id: 'Common Occupational Skills',
            title: t('recommendations.commonSkills.title'), 
            courses: [
                {title:  t('recommendations.commonSkills.appTradeMath'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694993'},
                {title: t('recommendations.commonSkills.handPowerTools'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694987'},
                {title: t('recommendations.commonSkills.healthSafety'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694986'},
                {title: t('recommendations.commonSkills.materialHandling'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694986'},
            ]
        },
        {
            id: 'Compétences Professionnelles Communes',
            title: t('recommendations.commonSkills.title'), 
            courses: [
                {title:  t('recommendations.commonSkills.appTradeMath'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694993'},
                {title: t('recommendations.commonSkills.handPowerTools'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694987'},
                {title: t('recommendations.commonSkills.healthSafety'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694986'},
                {title: t('recommendations.commonSkills.materialHandling'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694986'},
            ]
        },
        {
            id: 'Fabrication and Preperation of Components for Welding',
            title: t('recommendations.fabrication.title'), 
            courses: [
                {title: t('recommendations.fabrication.layoutTechniques'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695004'},
                {title: t('recommendations.fabrication.metalForming'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695004'},
            ]
        },
        {
            id: 'Fabrication et Préparation de Composants pour le Soudage',
            title: t('recommendations.fabrication.title'), 
            courses: [
                {title: t('recommendations.fabrication.layoutTechniques'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695004'},
                {title: t('recommendations.fabrication.metalForming'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695004'},
            ]
        },
        {
            id: 'Cutting and Gouging',
            title: t('recommendations.cutting.title'), 
            courses: [
                {title: t('recommendations.cutting.ofc'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694994'},
                {title: t('recommendations.cutting.pac'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694994'},
            ]
        },
        {
            id: 'La coupe et le Gougeage',
            title: t('recommendations.cutting.title'), 
            courses: [
                {title: t('recommendations.cutting.ofc'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694994'},
                {title: t('recommendations.cutting.pac'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694994'},
            ]
        },
        {
            id: 'Welding Processes',
            title: t('recommendations.welding.title'), 
            courses: [
                {title: t('recommendations.welding.powerSources'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
                {title: t('recommendations.welding.smawTheory'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694988'},
                {title: t('recommendations.welding.smawPractical'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694989'},
                {title: t('recommendations.welding.sawTheory'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.sawPractical'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694991'},
                {title: t('recommendations.welding.fcaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.gmaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.gtaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694998'},
                {title: t('recommendations.welding.weldingProcedures'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694988'},
                {title: t('recommendations.welding.weldQuality'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695000'},
            ]
        },
        {
            id: 'les procédés de soudage',
            title: t('recommendations.welding.title'), 
            courses: [
                {title: t('recommendations.welding.powerSources'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
                {title: t('recommendations.welding.smawTheory'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694988'},
                {title: t('recommendations.welding.smawPractical'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694989'},
                {title: t('recommendations.welding.sawTheory'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.sawPractical'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694991'},
                {title: t('recommendations.welding.fcaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.gmaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694990'},
                {title: t('recommendations.welding.gtaw'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694998'},
                {title: t('recommendations.welding.weldingProcedures'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694988'},
                {title: t('recommendations.welding.weldQuality'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695000'},
            ]
        },
        {
            id: 'Metallurgy, Distortion and Drawing Interpretation',
            title: t('recommendations.metallurgy.title'), 
            courses: [
                {title:  t('recommendations.metallurgy.jointDesign'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
                {title: t('recommendations.metallurgy.metallurgy'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695001'},
                {title: t('recommendations.metallurgy.appTradeMath'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694993'},
                {title: t('recommendations.metallurgy.distortion'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
    
            ]
        },
        {
            id: 'Métallurgie , Distorsion et Interprétation de dessin',
            title: t('recommendations.metallurgy.title'), 
            courses: [
                {title:  t('recommendations.metallurgy.jointDesign'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
                {title: t('recommendations.metallurgy.metallurgy'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695001'},
                {title: t('recommendations.metallurgy.appTradeMath'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9694993'},
                {title: t('recommendations.metallurgy.distortion'), link:'https://www.campusebookstore.com/EBooks/Book.aspx?ID=9695005'},
    
            ]
        },
    ];

    let curRecommendation = recommendations.filter(element => element.id === item)[0];
    return (
        <>
        {
            curRecommendation ? (
                <Box className={classes.listItem} component="li">
                    <Grid container spacing={2} justify="center" alignItems="center" style={{margin: 0,width: '100%',}}> 
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={1}>
                            {
                                curRecommendation.title && 
                                <Box py={1}>
                                    <Typography variant="body1" component="h5" align="center" gutterBottom>
                                        <b>{curRecommendation.title}</b>
                                    </Typography>
                                </Box>
                            }                   
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {
                                curRecommendation.courses.length > 0 &&
                                curRecommendation.courses.map((item, idx) =>
                                    <Box key={idx} my={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        <Link 
                                            className={classes.link} 
                                            to={{ pathname:item.link}}
                                            target="_blank" 
                                        >
                                            {/* <Typography variant="body1" align="center" gutterBottom> */}
                                                {item.title}
                                            {/* </Typography> */}
                                        </Link>
                                    </Box>
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            ) : null
        }
        
        </>
    )
}

const Recommendations = ({items}) => {
    const classes = useRecommendationsStyles();
    const { t } = useTranslation('result');
    return (
        <Box mb={3} className={classes.listSection}>
            {
                items.map((item, idx) => (
                    <RecommendationItem 
                        key={idx}
                        item={item}
                    />
                ))
            }
        </Box>
    )
}

const renderContent = (data,results, error, classes, getScore, getPercentage, printDocument, t) => {
    const headCells = [
        {id: 'topic', label: t("table1.colTitle1")},
        {id: 'highlight', label: t("table1.colTitle2")},
        {id: 'score', label: t("table1.colTitle3")},
    ];

    const topics = [
        {
            id: "Common Occupational Skills",
            label: t('topics.commonSkills')
        },
        {
            id: "Compétences Professionnelles Communes",
            label: t('topics.commonSkills')
        },
        {
            id: "Cutting and Gouging",
            label: t('topics.cutting')
        },
        {
            id: "La coupe et le Gougeage",
            label: t('topics.cutting')
        },
        {
            id: "Fabrication and Preperation of Components for Welding",
            label: t('topics.fabrication')
        },
        {
            id: "Fabrication et Préparation de Composants pour le Soudage",
            label: t('topics.fabrication')
        },
        {
            id: "Metallurgy, Distortion and Drawing Interpretation",
            label: t('topics.metallurgy')
        },
        {
            id: "Métallurgie , Distorsion et Interprétation de dessin",
            label: t('topics.metallurgy')
        },
        {
            id: "Welding Processes",
            label: t('topics.welding')
        },
        {
            id: "les procédés de soudage",
            label: t('topics.welding')
        }
    ]
    
    return data ? (
        <>

        <Container maxWidth="xl" id="results">
            <section className={classes.section}> 
                <Box mb={3} mt={3}>
                    <Typography variant="body1" gutterBottom> 
                        {t("text1")}
                    </Typography>
                </Box>
                <Box>
                    <Typography>
                        <Trans i18nKey="result:text2">
                            Here at CWG group, we offer a range of services geared specifically for welders at all points in their career, please visit us at out website 
                            (<Link to="https://www.cwbgroup.org/" target="_blank" className={classes.link}>https://www.cwbgroup.org/</Link>) to see more about what we do and how we can help you further along your welding career.
                        </Trans>
                    </Typography>
                </Box>
                
            </section>
            
            <section className={classes.section} >
                <Box mb={3} display="flex" justifyContent="center">
                    <img src={iconResult} alt={t("iconAlt")} />
                </Box>
                <Typography variant="h6" align="center" color="secondary" gutterBottom>
                    <b>{t("resultTitle")}</b>
                </Typography>
                <Typography variant="h6" align="center" color="secondary" gutterBottom>
                    <b>{t("scoreText")} {data.score}/{data.totalScore}</b>
                </Typography>
                <Box>
                    <Box bgcolor="secondary.light" p={1} my={3}>
                        <Typography variant="body1" align="center" gutterBottom>
                           { getPercentage(data.score, data.totalScore) >= 70 ? t("qualifiedText"): t("notQualifiedText") }
                        </Typography>
                    </Box>
                </Box>
            </section>
            <section className={classes.section} >
                <Box mb={3} id="results">
                    <Box my={2}>
                        <Typography variant="body1" color="secondary" align="left" gutterBottom>
                            <b>{t("table1.title")}</b>
                        </Typography>
                    </Box>

                    <div className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map(headCell => (
                                            <TableCell key={headCell.id}>
                                                <Typography align="center" variant="body2">                                                   
                                                    <b>{headCell.label}</b>
                                                </Typography>                         
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.entries(data.topic).map(([key, value], idx) => {
                                        let curTopic = topics.filter((element) => element.id == key)[0];
                                        if(curTopic){
                                            return (
                                                
                                                <TableRow key={idx}>
                                                    <TableCell><Typography align="center" variant="body2">{curTopic.label}</Typography></TableCell>
                                                    <TableCell><Typography align="center" variant="body2">{curTopic.label}</Typography></TableCell>
                                                    <TableCell><Typography align="center" variant="body2">{getScore(value.studentAnswerCorrect, value.totalQuestionPoints)}</Typography></TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </Box>

                {
                    results.length > 0 && 
                    <Box mb={2}>
                        <Box my={2}>
                            <Typography variant="body1" color="secondary" align="left" gutterBottom>
                                <b>{t("table2.title")}</b>
                            </Typography>
                        </Box>
                        <Box my={1} >
                            <Recommendations items={results}/>
                        </Box>
                    </Box>
                }

                <Box>
                    <Box mb={2}>
                        <Typography variant="subtitle2" color="textPrimary" align="left" gutterBottom>
                            {t("table2.bottomText")}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                    <Button
                        endIcon={<ArrowForwardIcon />}
                        color="primary" 
                        size="large"
                        variant="contained" 
                        disableElevation
                        onClick={printDocument}
                        className={classes.buttonRoot}
                    >
                        <Typography variant="h5">
                        {t("savePdfButton")}
                        </Typography>
                    </Button>
                    </Box>
                </Box>
            </section>
               
        </Container>
            <section className={classes.section}>
                <Box p={4} className={classes.bottomSection}>
                    <Container maxWidth="lg">
                        <Box mb={3}>
                            <Typography variant="h6" align="center" color="secondary" gutterBottom>
                                <b>{t("bottomSectionTitle")}</b>
                            </Typography>
                        </Box>
                        <Box width="auto">
                            <Grid container spacing={3} style={{margin: 0,width: '100%',}} justify="center" alignItems="center">
                                <Grid item xs={12} sm={4} style={{textAlign: 'center'}}>
                                    <Button
                                        color="primary" 
                                        size="small"
                                        variant="contained" 
                                        disableElevation
                                        className={classes.buttonRoot}
                                        target="_blank" 
                                        href={"https://www.jobbank.gc.ca/home"} 
                                    >
                                        <Typography variant="h5">
                                        {t("jobBankButton")}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{textAlign: 'center'}}>
                                    <Button
                                        color="primary" 
                                        size="small"
                                        variant="contained" 
                                        disableElevation
                                        className={classes.buttonRoot}
                                        target="_blank" 
                                        href={"https://www.weldingindustryjobs.org/"}  
                                    >
                                        <Typography variant="h5">
                                        {t("cwbJobButton")}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>

                <Box my={3}>
                    <Paper className={classes.image} style={{ backgroundImage: `url(${image})` }}>
                    </Paper>
                </Box>
                
                {/** DISABLED- SUCCESS STORIES */}
                {/* <Box my={3}>
                    <Container maxWidth="lg">
                    <Typography variant="h6" align="center" color="secondary" gutterBottom>
                        <b>Click <Link to="/success-stories" className={classes.link}>here</Link> to view the success stories of this program</b>
                    </Typography>
                    </Container>
                </Box> */}
            </section>
        </>
    ) : error ? (
        <Container maxWidth="lg">
            <Box my={3} p={1} bgcolor="secondary.light">
                <Typography variant="subtitle2" color="error">
                    {error}
                </Typography>
            </Box>
        </Container>
    ) : (
        <Container maxWidth="lg">
            <Box my={4} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="secondary"/>
            </Box>
        </Container>   
    )
}

const Result = () => {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const {token, user} = useAuth();
    const [results, setResults] = useState([]);
    const { t } = useTranslation(['result', 'error']);
   
    
    useEffect(() => {
            if(user?.email) {
                fetchUserQuizId(user.email).then(response => {
                    
                    return fetchQuizResults(response)
                }).then(response =>{
                    
                    let result = []
                    if ( Object.keys(response.topic).length > 0 )  {
                        Object.entries(response.topic).map(([key, value]) => {
                            let percentage = getPercentage(value.studentAnswerCorrect, value.totalQuestionPoints);

                            if (percentage < 70) {
                                result.push(key);
                                // let recommendation = recommendations.find(element => element.id === key)
                                // if(recommendation) {
                                //     result.push(recommendation)
                                // }
                                
                            }
                        });
                    }
                    setResults(result);
                    setData(response);

                })
                .catch((e) => {
                    
                    setError(t("quizResultsNotFoundError"))
                })
            } 
    }, [user]);

    const printDocument = () => {
        const element = document.getElementById('results');
        const options = {
            filename: "results.pdf",
          };
          return domToPdf(element, options, () => {
            console.log('pdf generated');
            });
    }
    const getPercentage = (score, totalScore) => {
        return +(((score / totalScore) * 100).toFixed(2));
    }
    const getScore = (score, totalScore) => {
        let percentage = getPercentage(score, totalScore)
        if(percentage >= 70) return `${t("passText")} (${score}/${totalScore})`;
        else if (percentage <70 ) return `${t("needsImprovementText")} (${score}/${totalScore})`;
    }

    return (
        <>
            {
                token ? (

                    renderContent(data, results, error, classes, getScore, getPercentage, printDocument, t)
                ) : (
                    <Container maxWidth="lg">
                        <Box my={3}>
                            <Typography variant="h3" align="center">
                                {t('error:loginRequired')}
                            </Typography>
                        </Box>
                    </Container>
                )
            }   
        </>
    );
    
}

export default Result;
