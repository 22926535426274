import React from 'react'
import { 
    makeStyles,
    Grid,
    Typography,
    Box
} from '@material-ui/core';
import {getProvinceFromId} from '../data';
import WelderBox from '../../../components/WelderBox'
import RedSeal from '../../../components/RedSeal';
import ListBox from '../../../components/ListBox';
import ArrowBox from '../../../components/ArrowBox';
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        backgroundColor: theme.palette.common.white,
        padding:theme.spacing(1),
    },
}));


const BC = () => {
    const {t} = useTranslation('regulation');
    const classes = useStyles();
    const section2WelderData = [
        {
            title:t('pathways.welderLvl1'), 
            subtitle:`${t('pathways.welderLvl1')} 240 ${t('pathways.hours')} - ${t('pathways.itaExam')}`, 
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:""
        },
        {
            title:t('pathways.welderLvl2'), 
            subtitle:`${t('pathways.welderLvl1')} 240 ${t('pathways.hours')}`, 
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:""
        },
        {
            title:t('pathways.welderLvl3'), 
            subtitle:`${t('pathways.welderLvl1')} 300 ${t('pathways.hours')}`, 
            secondaryTitle:t('pathways.BC.section2.Box3'),
            secondarySubtitle:t('pathways.workTraining'), 
            hours:`4620 ${t('pathways.hours')}`
        }
    ];
    return (
        <>  
            <div className={classes.container}>
                {/**Section 1 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.BC.section1.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        <ArrowBox>
                            <WelderBox 
                                title={t('pathways.BC.section1.Box1')}
                            />
                        </ArrowBox>
                        <ArrowBox>
                            <WelderBox 
                                title={t('pathways.BC.section1.Box2')}
                            />
                        </ArrowBox>
                        <ArrowBox>
                            <WelderBox 
                                title={t('pathways.welderLvl3')}
                                subtitle={`${t('pathways.techTraining')} 300 ${t('pathways.hours')}`}
                                secondarySubtitle={t('pathways.workTraining')}
                                hours={`4620 ${t('pathways.hours')}`}
                            />
                        </ArrowBox>
                        <RedSeal />
                    </Grid>
                </Box>  

                {/**Section 2 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.BC.section2.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 

                        {
                            section2WelderData.map((data, idx) =>
                                <ArrowBox>
                                    <WelderBox 
                                        key={idx}
                                        title={data.title}
                                        subtitle={data.subtitle}
                                        secondaryTitle={data.secondaryTitle}
                                        secondarySubtitle={data.secondarySubtitle}
                                        hours={data.hours}
                                    />
                                </ArrowBox>
                            )
                        }
                        <RedSeal />
                    </Grid>
                </Box>

                {/**Section 3 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.BC.section3.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        <ArrowBox mdCols={3}>
                            <WelderBox 
                                subtitle={t('pathways.BC.section3.Box1')}
                            />
                        </ArrowBox>
                        <ArrowBox mdCols={3}>
                            <WelderBox 
                                subtitle={t('pathways.BC.section3.Box2')}

                            />
                        </ArrowBox>
                        
                        <RedSeal mdCols={3} />
                    </Grid>
                </Box>

                {/**Section 4 */}
                <Box p={2} my={8} borderColor="secondary.main" border={1} borderRadius="1rem">
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.BC.section4.title')}</b>
                        </Typography>
                        <Box mt={3} borderRadius="50%" borderColor="secondary.main" border={1} width="100px" height="100px" display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="h4" align="center" color="secondary">
                                <b>{t('pathways.BC.section4.optional')}</b>
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        <ArrowBox mdCols={3}>
                            <RedSeal mdCols={12}/>
                        </ArrowBox>
                        <ArrowBox mdCols={3}>
                            <WelderBox 
                                title={t('pathways.BC.section4.mpawText')}
                                subtitle={`${t('pathways.techTraining')} 150 ${t('pathways.hours')}`}
                                secondaryTitle={`${t('pathways.workTraining')} 900 ${t('pathways.hours')}`}
                                secondarySubtitle={t('pathways.itaExam')}
                                hours={``}
                            />
                        </ArrowBox>
                        <Grid item>
                            <WelderBox  
                                title={t('pathways.BC.section4.mpawText')}
                            />
                        </Grid>
                        
                        
                    </Grid>
                </Box>
            </div>
           
        </>
    )
}

export default BC
