import {buildUrl, snakeToCamel} from '../utils/common';


export const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
     mode: 'cors',
    // mode:'no-cors',
};

export const setAuthInfo = token => {
    localStorage.setItem('cwbJwt', token);
    setAuthHeader(token);
    
};

export const removeAuthInfo = () => {
    localStorage.removeItem('cwbJwt');
    setAuthHeader(false);
};

export const setAuthHeader = (token) => {
    if (token) {
        config.headers['Auth-Token'] = token;
    } else {
        delete config.headers['Auth-Token'];
    }
};

const preprocess = response => {
    if (response.status === 204) {
        return Promise.resolve({
            status: response.status,
        });
    } else if (response.status >= 100 && response.status <= 399) {
        return response.json().then(data => snakeToCamel(data));
    } else {
        return response.json().then(data => Promise.reject(snakeToCamel({
            ...data,
            status: response.status,
        })));
    }
};

const getJSON = (url, query = {}) => (
    fetch(
        buildUrl(url, query),
        config,
    )
        .then(preprocess)
);

const postJSON = (url, payload, query = {}) => (
    fetch(
        buildUrl(url, query),
        {
            ...config,
            method: 'POST',
            body: JSON.stringify(payload),
        },
    )
        .then(preprocess)
);

export default {
    postJSON,
    getJSON
}