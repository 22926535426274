import React,  {useEffect, useState}  from 'react'
import { makeStyles, Grid, Typography, Box, Button, useMediaQuery, TextField, Container} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import {useForm, Form} from '../components/useForm';
import Input from '../components/Input';
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '80%',
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}));
const initialValues = {
    currentPassword: "",
    newPassword: "",
}
const ChangePassword = () => {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');

    const validate = () => {
        let temp = {}
       
        if (values.password.length !== 0) {
            if(values.password.length > 5) {
                temp.password = ""
            } else {
                temp.password = "Minimum 6 numbers required."
            }
        } else {
            temp.password = "This field is required."
        }

        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x === "")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
        if(validate()){
            setSubmitting(true);
            api.emailPasswordReset(values.email)
                .then(() => {
                    setSubmitting(false);
                    
                })
                .catch(err => {
                    setFormError('Could not reset password');
                    setSubmitting(false);
                });
        }
    }

    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);

    return (
        <div>
            <Container maxWidth="sm">
                <Box my={3}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">
                                Reset Password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Form onSubmit={handleSubmit}>
                                {
                                    formError &&
                                    <Box my={3} p={1} bgcolor="secondary.light">
                                        <Typography variant="subtitle2" color="error">
                                            {formError}
                                        </Typography>
                                    </Box>
                                }
                                <Input  
                                    label="Current Password"
                                    name="currentPassword"
                                    value={values.currentPassword}
                                    onChange= {handleInputChange}
                                    error={errors.currentPassword}
                                />
                                <Input  
                                    label="New Password"
                                    name="newPassword"
                                    value={values.newPassword}
                                    onChange= {handleInputChange}
                                    error={errors.newPassword}
                                />
                                <Box mx={1} my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <Button 
                                        color="primary" 
                                        size="large" 
                                        variant="contained" 
                                        disableElevation
                                        className={classes.buttonRoot}
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Change Password
                                    </Button>
                                </Box>
                            </Form>
                        </Grid>
                        
                    </Grid>
                </Box>

                
            </Container>
        </div>
    )
}

export default ChangePassword
