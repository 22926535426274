import React from "react";

import { 
    AppBar, 
    Toolbar, 
    useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { withRouter } from "react-router";


const languageOptions = [
  {code: 'en', label: 'EN ', iconClass: 'CA'},
  {code: 'fr', label: 'FR ', iconClass: 'CA'},
  {code: 'uk', label: 'UA ', iconClass: 'UA'}
]

const Navigation = (props) => {
    
    const {location, setOpenSignup, i18n} = props;
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const classes = useStyles();
    const [language, setLanguage] = React.useState(i18n.language);

    const handleLanguageChange = (e) => {
      const code = e.target.value;
      if (code === 'en' && language !== 'en') {
          i18n.changeLanguage('en');
          setLanguage('en');
      } else if (code === 'fr' && language !== 'fr') {
          i18n.changeLanguage('fr');
          setLanguage('fr');
      } else if (code === 'uk' && language !== 'uk') {
          i18n.changeLanguage('uk');
          setLanguage('uk');
      }
  }
    // const [openPopup, setOpenPopup] = React.useState(false);
    // const [openSignupConfirm, setOpenSignupConfirm] = React.useState(false);
    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {
                    isSmallScreen ? 
                        <MobileMenu 
                          setOpenPopup={setOpenSignup} 
                          languageOptions={languageOptions}
                          language={language} 
                          handleLanguageChange={handleLanguageChange}
                        /> :
                        <DesktopMenu 
                          location={location.pathname} 
                          setOpenPopup={setOpenSignup} 
                          languageOptions={languageOptions}
                          language={language} 
                          handleLanguageChange={handleLanguageChange}
                        />
                    }
                    
                </Toolbar>
            </AppBar>

            {/* <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <Signup setOpenPopup={setOpenPopup} setOpenSignupConfirm={setOpenSignupConfirm}/>
            </Popup>

            <Popup
              openPopup={openSignupConfirm}
              setOpenPopup={setOpenSignupConfirm}
            >
              <SignupConfirm setOpenPopup={setOpenSignupConfirm}/>
            </Popup> */}
            
        </>
        


  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
      backgroundColor: "white",
      color: "black"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "black",
    textDecoration: "none",
    margin: theme.spacing(1, 1.5),
  },
  activeLink: {
    borderBottom: '0.4rem solid black',
  },
  logo: {
      height: '50px',
      width:'50px'
  },
  toolbar: {
    // paddingLeft: '0px',
    // paddingRight: '0px'
  }
}));
const NavigationWithRouter = withRouter(Navigation); 
export default NavigationWithRouter;
