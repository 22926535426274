import React from 'react'
import { 
    Typography,
    Box,
    Grid
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const RedSeal = ({mdCols=3}) => {
    const {t} = useTranslation('regulation');
    return (
        <Grid item xs={12} md={`${mdCols}`} >
            
                <Box  p={1} maxWidth="210px" mx="auto" borderRadius="1rem" overflow="hidden" bgcolor="secondary.light">
                    <Box p={1} border={1} bgcolor="common.white">
                        <Typography variant="body1" align="center">
                            <Box fontWeight="fontWeightBold" p={1}>
                               {t('pathways.redSeal')}
                            </Box>
                        </Typography>
                    </Box>
                    <Box p={1} >
                        <Typography variant="body1"  align="center">
                            <b>{t('pathways.certOfQualWelder')}</b>
                        </Typography>
                    </Box>
                </Box>
          
        </Grid>
    );
}

export default RedSeal
