import React from "react";
import { 
    Box,
    Typography
  } from "@material-ui/core";
import Item from "./Item"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({ 
    listSection: {
        '& li:first-child': {
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            // borderBottom: 1,
            // borderBottomStyle: 'solid',
            //  borderBottomColor: theme.palette.secondary.main   
        },
        '& li:nth-last-child(1)': {
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            borderBottom: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.secondary.main   
        },
        [theme.breakpoints.down('sm')]: {
            '& li:first-child': {
                border: 'none',
            },
            '& li:nth-last-child(1)': {
                border: 'none',
            },
            
        }
    }
  }));

const ListSection = ({title="", items}) => {
    const classes = useStyles();
    return (
        <>
        {title ? (
            <Typography variant="h3" align="left" gutterBottom>
                <Box mb={2}>
                    {title}
                </Box>
            </Typography>
        ): null}
        
        <Box mb={3} className={classes.listSection}>
            {
                items.map((item, idx) => (
                    <Item 
                        key={idx}
                        item={item}
                        // title = {item.title}
                        // body={item.body}
                        // link={item.link}
                    />
                ))
            }
        </Box>
        </>
    );
}

export default ListSection;