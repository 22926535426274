import React from 'react'
import { makeStyles, FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, Box, Typography } from '@material-ui/core';
import Country from "flagit";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormLabel-root':{
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '1rem',
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main
            }
            //border: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiSelect-icon': {
            color: theme.palette.secondary.main,
        }
    }
}));
const Select = (props) => {
    const classes = useStyles();
    const { name, label, value, onChange, options, hideNoneOption=false, error=null } = props;

    return (
        <FormControl
            variant="outlined"
            className={classes.root}
            error={error? true : false}
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect 
                label={label}
                name={name}
                value={value}
                onChange={onChange}
            >
                {
                    !hideNoneOption ? (<MenuItem value="">None</MenuItem>) : null

                }
                
                {
                    options.map(
                    item => (
                            <MenuItem 
                                key={item.code} 
                                value={item.code}
                            >
                                <Box display="flex" flexDirection="row" justifyContent="between" alignItems="center">
                                    <Typography style={{marginRight: '5px', marginTop: '1px'}}  variant="body1" align="left">{item.label}</Typography>
                                    {item.iconClass &&
                                        <Country countryShort={item.iconClass} size="sm" />
                                    }
                                </Box>
                                
                            </MenuItem>
                        )
                    )
                }
            </MuiSelect>
            {
                error !=="" && 
                <FormHelperText>{error}</FormHelperText>
            }
        </FormControl>
    )
}

export default Select
