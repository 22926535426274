import React from 'react'
import { makeStyles, Grid, Typography, Box, Button,} from "@material-ui/core";
import { Link, useLocation, } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin:'0 auto',
    },
    buttonContainer: {
        textAlign: 'center'
    }
}));

const SignupConfirm = (props) => {
    const classes = useStyles();
    const {setOpenPopup} = props;
    let location = useLocation();
    console.log('signupConfirmLocation', location);
    return (
        <div>
            <Box mb={4}>
                <Typography variant="h3" align="center">
                    Sign up confirmed
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography variant="body2" gutterBottom align="center">
                    Thank you for signing up for the Welder Competency and Credential Assessement Program (WCCAP).
                </Typography>

                {/* <Typography variant="body2" gutterBottom align="center">
                    You will receive a confirmation email to your inbox shortly.
                </Typography> */}
            </Box>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    <Button 
                        color="primary" 
                        size="small" 
                        variant="contained" 
                        disableElevation
                        className={classes.buttonRoot}
                        component={Link}
                        to={"/"}
                        // onClick={e=>setOpenPopup(false)}
                    >
                        Back to home
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="contained" 
                    disableElevation
                    className={classes.buttonRoot}
                    component={Link}
                    to={{
                        pathname:"/signup",
                        // state: {background: location}
                         state: { background: {hash: "", pathname: "/", search: "", state: undefined}, goTo: '/start-assessment' }
                         //state: { goTo: '/start-assessment'}
                    }}
                    // onClick={e=>setOpenPopup(false)}
                >
                    Start Assessment
                </Button>
                </Grid>
            </Grid>
           
        </div>
    )
}

export default SignupConfirm
