import React from 'react'
import { 

    Typography, 

    Box,
    Card,
    CardContent,
    CardMedia,
    makeStyles,
 
} from "@material-ui/core";
import peopleImage1 from '../assets/programInformation/peopleImage1.png';
import peopleImage2 from '../assets/programInformation/peopleImage2.png';
import peopleImage3 from '../assets/programInformation/peopleImage3.png';
import IconArrowEnabled from '../assets/icons/icon-arrow-enabled.png';
import Slider from "react-slick";

const useSlideStyles = makeStyles((theme) => ({
    cardRoot: {
        margin: '0 auto',
        maxWidth: '300px',
        padding: theme.spacing(2),
        border: '1px solid black',
        borderRadius: '2rem'
    },
        cardMedia: {
        margin: '0 auto',
        width: '200px',
        height: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '150px',
            height: '150px',
        }
    },
    quoteContainer: {
        textAlign:'center',
        marginBottom: theme.spacing(1),
    },
    quote: {
        color: theme.palette.primary.main,
        fontSize: '4em',
    },
    slide: {
        // margin: '0 auto',
        // minWidth: '33%',
        //  margin: theme.spacing(2),
        // [theme.breakpoints.down('sm')]:{
        //     minWidth: '50%'
        // },
        // [theme.breakpoints.down('xs')]:{
        //     minWidth: '100%'
        // },
    },
}))


const Slide = ({item}) => {
    const classes = useSlideStyles();
    return (
        <div className={classes.slide}>
            <Card className={classes.cardRoot}>
                <div className={classes.quoteContainer}>
                <span className={classes.quote}>&#8220;</span>
                </div>
                <CardMedia 
                className={classes.cardMedia}
                image={item.image}
                />
                <CardContent>
                <Typography gutterBottom variant="h5" color="secondary" align="center">
                    <Box my={2}>
                    {item.name}
                    </Box>
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p" align="left">
                    {item.body}
                </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

const success = [
    {image: peopleImage1, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage2, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage3, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage3, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage3, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'}

];

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        maxWidth: '1280px',
        '& .slick-arrow.slick-disabled': {
            opacity: 0.5
        },
        '& .slick-next' :{
            '&::before' : {
                content: '',
                display: 'none',
            }
        },
        '& .slick-prev' :{
            '&::before' : {
                content: '',
                display: 'none',
            }
        }


    },
   
    button: {
        position:'absolute',
        top:'50%',
        transform: `translateY(-50%)`,
        cursor: 'pointer'
    },
    buttonPrev: {
        left: 0
    },
    buttonNext: {
        right: 0
    },
    
    
    slidesContainer: {
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
        scrollBehavior: 'smooth',
        // flexWrap: 'nowrap'
    },

}));

const useArrowStyles = makeStyles((theme) => ({
    iconButton: {
        position: 'absolute',
        top:'50%',
        transform: 'translateY(-50%)',
    },
    iconButtonLeft: {
        left: 0
    },
    iconButtonRight: {
        right: 0
    },
    prevImage: {
        transform: `rotate(180deg)`,
        width: '40px',
        height:'40px'
    },
    nextImage: {
        width: '40px',
        height:'40px'
    },
}));
const PrevArrow = (props) => {
    const classes= useArrowStyles();
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", top:"50%", transform: "translateY(-50%)", left: "0", zIndex:2}}
            onClick={onClick}
        >
            <img src={IconArrowEnabled} className={classes.prevImage} alt="previous"/>
        </div>
        
        // <IconButton onClick={onClick} className={className} style={{ ...style, display: "block"}}>
        //     <img src={IconArrowEnabled} className={classes.prevImage}/>
        // </IconButton>
    )
}
const NextArrow = (props) => {
    const classes= useArrowStyles();
    const { className, style, onClick } = props;
    
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", top:"50%", transform: "translateY(-50%)", right: "0"}}
            onClick={onClick}
        >
            <img src={IconArrowEnabled} className={classes.nextImage} alt="next"/>
        </div>
        // <IconButton onClick={onClick} className={className} style={{ ...style, display: "block"}}>
        //     <img src={IconArrowEnabled} className={classes.nextImage}/>
        // </IconButton>
    )
}

const Carousel = () => {
    const classes = useStyles();
    let settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        // arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              }
        ]
    }

    return (
        <div className={classes.wrapper}>
            <Slider {...settings}>
                {success.map((item, idx) => (
                    <Slide key={idx} item={item} />
                    
                ))}
            </Slider>
        </div>
    )
}

export default Carousel;