import React, {useState, useLayoutEffect} from "react";
import { makeStyles, Paper, Grid, Typography, Box, Button, useMediaQuery, Container } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import homeHeroDesktop from '../assets/home/home-header-desktop.png'
import homeHeroMobile from '../assets/home/home-header-mobile.png'
import bodyImage1 from '../assets/home/image2.png';
import bodyImage2 from '../assets/home/bodyImage2.jpeg';
import homeImage2 from '../assets/home/image3.png';
import iconAssist from '../assets/icons/1-banner1.png';
import iconWelder from '../assets/icons/2-banner2.png';
import iconInteractive from '../assets/icons/icon-interactive.png';
import iconProcess from '../assets/icons/icon-process.png';
import iconTrend from '../assets/icons/icon-trend.png';
import iconBook from '../assets/icons/icon-book.png';
import iconBurner from '../assets/icons/icon-burner.png';
import iconArrow from '../assets/icons/icon-arrow@2x.png';
import {useAuth} from '../components/useAuth';
import {Helmet} from "react-helmet";
import Banner from "../components/Banner"
import {useTranslation, Trans} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  section1Container: {
    marginBottom: theme.spacing(4),
  },
  image1Styles: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '300px',
    width:'100%',
    margin: '0 auto',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
     borderRadius: 0
    },
  },
  image2Styles: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '450px',
    width:'100%',
    margin: '0 auto',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
     borderRadius: 0,
     height: '300px',
    },
  },
  section2Container: {
    marginBottom: theme.spacing(4),
  },
  section2Text: {
    textAlign: 'left'
  },
  banner: {
    position:'relative'
  },
  heroImage: {
    width:'100%',
    maxWidth: '100%',
    minHeight:'300px',
    maxHeight:'300px',
    height: 'auto',
  },
  heroContainer: {
    position:'absolute',
    width: '60%',
    top:0,
    right: 0,
    marginTop:'5%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop:'1.5rem',
      
    },
  },
  heroIcon: {
    height: '100px',
    width: '100px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      width: '50px',
    },
  },
  iconContainer: {
    paddingRight: theme.spacing(1)
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]:{
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 3)
    }
  },
  textContainer: {
    paddingRight: '40px',
  },

  bannerSection: {
    backgroundColor: theme.palette.secondary.main,
    marginTop:'-32px',
    position: 'relative',
  },
  bannerContainer: {
    flexWrap: 'nowrap',
    alignItems:'center',
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(2, 3),
    }
  },
  overlayContainer: {
    backgroundColor: 'black',
    position: 'absolute',
    opacity: '0.9',
    zIndex: 998,
    bottom: 0,
    width: '100%',
    padding: theme.spacing(1, 0),
  },
  overlayWrapper: {
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px',
    }
  },
  bannerTextWrapper: {
    padding: theme.spacing(0, 4)
  },
  whiteText: {
    color: '#fff'
  },
  iconFont: {
    fontSize: '4rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    }
  },
  sectionPadding: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 3)
    }
  },
  sectionWrapper: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  sectionHeader: {
    display:'flex',
    alignItems:'center',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1,0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3),
      // justifyContent: 'center',
    }
  },
  headerTextContainer: {
    
  },
  bodyMargin: {
    padding: theme.spacing(2, 0),
    marginLeft: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(2, 3),
      // justifyContent: 'center',
    }
  },
  bodyTextContainer: {
    padding: theme.spacing(2, 0),
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(2, 3),
      // justifyContent: 'center',
    }
  },
  headerIconContainer: {
    display: 'flex',
    alignItems:'center',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]:{
      marginLeft: 0,
      marginRight: theme.spacing(2),
    }
  },
  headerIcon: {
    height:'40px',
    width: '40px',
  },
  vertical_dotted_line: {
    borderLeft: '4px dotted black',
    height:'100%',
    display: 'inline-block'
  },
  vertical_line_wrapper: {
    position:'relatve',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    }
  },
  downArrow: {
    height: '40px',
    width: '40px',
    transform: 'rotate(90deg)',
  },
  line_container: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
 
  section2IconContainer:{

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
 
  timelineContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    justify: 'space-between',
    "&:after": {
      content: "''",
      position: 'absolute',
      zIndex: '-1',
      left:'20%',
      width:'70%',
      // height:'2px',
      top: '25%',
      // backgroundColor: theme.palette.secondary.main
      borderTop: `2px dotted ${theme.palette.secondary.main}`
    },
    [theme.breakpoints.down('lg')]: {
      "&:after": {
        top:'17%'
      }

    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
      "&:after": {
        display: 'none'
      }

    }
  },
  timelineListItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    flexBasis: '250px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '0',
    },
  },
  line: {
    position: 'relative',
    textAlign: 'center',
      display: 'inline-block',
    // '&::after': {
    //   content: "''",
    //   // backgroundColor: theme.palette.secondary.main,
    //   position: 'absolute',
    //   top: '50%',
    //   // height: '2px',
    //   borderTop: `2px dotted ${theme.palette.secondary.main}`,
    //   left: '100%',
    //   width: '100px',
    //   display: 'inline-block',
    //   verticalAlign: 'middle',

    //   [theme.breakpoints.down('sm')]: {
    //     display:'none',
    //   }
    // },
  },
  timelineIcon: {
    marginBottom: theme.spacing(1),
    height: '80px',
    width: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '50px'
    },
  },
  verticalLine: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display:'block',
      position: 'relative',
      zIndex: '-1',
      height: '40px',
      marginLeft: '50%',
      borderLeft: `1px dashed ${theme.palette.secondary.main}`,
    },
  },
  section3Container: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },

  },
  section3Image: {
    //backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '450px',
  },
  section3Text: {
    marginTop: theme.spacing(2),
  },  
  closeIcon: {
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  bannerIcon: {
    height: '160px',
    width: '160px',
    [theme.breakpoints.down('md')]: {
      height: '90px',
      width: '90px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '70px',
      width: '70px',
    },
  },
  bannerIconMargin: {
    marginRight: theme.spacing(3),
  },
  bannerIconContainer: {
    textAlign:'center',
  },
  container: {
    
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      // justifyContent: 'center',
    }
  },
  buttonRoot: {
    textTransform: 'none',
    borderRadius: '0.8rem',
    padding: '4px 24px'
},
  scroll: {
    position:'fixed',
    bottom: '0',
  },
  image: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    objectPosition: 'center'
  }

}));

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['home', 'common']);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const lessThan400 = useMediaQuery('(max-width:400px');
  const [overlay, setOverlay] = React.useState(true);
  const [scrolled, setScrolled] = useState(false);
  let location = useLocation();
  let history = useHistory();
  const {user, token} = useAuth();



  useLayoutEffect(() => {
    const handleScroll = e => {
      setScrolled(window.scrollY > 0)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, []);

  const closeOverlay = () => {
    setOverlay(!overlay);
  }

  const handleClick = (e) => {
    if(token) {
      history.push({
        pathname: '/start-assessment'
      })
    } else {
      history.push({
        pathname: '/signup',
        state: {background: {hash: "", pathname: location.pathname, search: "", state: undefined}, goTo: '/start-assessment' }
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('metaTitle')}
          
        </title>
        <meta name="description" content={t('metaDesc')} />
      </Helmet>
      {/** Banner */}
      <Banner 
        
        alt={''} 
        iconSrc={iconBurner} 
        iconAlt={t('bannerIconAlt')} 
        // title={'Welder Competency and Credential Assessment (WCCA)'}
        title={t('home:banner')}
        jc={'center'}
      />
      {/* <div className={classes.banner}>
       
        <picture>
          <source srcSet={homeHeroDesktop} media="(min-width: 640px)"/>
          <img src={homeHeroMobile} alt="Two welders in a factory protective gear with materials on the work bench."  className={classes.heroImage}/>
        </picture>
        <div className={classes.heroContainer}>
          <div className={classes.heroContent}> 

            <div className={classes.iconContainer}>
              <img src={iconBurner} alt="weld" className={classes.heroIcon} />
            </div>

                <div className={classes.textContainer}>
                  <Typography variant="h1" align="left">
                    Welder Competency and Credential Assessment (WCCA)
                  </Typography>
                </div>

          </div>
        </div>
      </div> */}
      {/** End Banner */}
                  {/* <Typography variant="h3" align="left">
                    <b>Credential Assessment (WCCA)</b>
                  </Typography> */}
              {/* {
                lessThan400 ? (
                  <>
                  <Typography variant="h3" align="left">
                    <b>Welder Competency and </b>
                  </Typography>
                  <Typography variant="h3" align="left">
                    <b>Credential Assessment (WCCA)</b>
                  </Typography>
                </>
                ): (
                  <>
                  <Typography variant="h2" align="left">
                    <b>Welder Competency and </b>
                  </Typography>
                  <Typography variant="h2" align="left">
                    <b>Credential Assessment (WCCA)</b>
                  </Typography>
                </>
                )
              } */}
              
            
      
      <section className={`${classes.bannerSection}`} >
        <Container maxWidth='xl' className={`${classes.container} `}>
        <div className={classes.sectionWrapper}>
          <Grid container spacing={1} justify="space-between" alignItems="flex-start" className={classes.bannerContainer} style={{margin: 0,width: '100%',}}>
            
            <Grid item xs={8} sm={6} className={classes.bannerTextWrapper}>

              <Grid container alignItems="flex-start" direction="column">
                <Grid item>
                  <Box mb={2}>
                    <Typography variant="body1" align="left"  gutterBottom className={classes.whiteText}>
                    
                      <b>{t('subBanner.text1')}</b>
                    
                    </Typography>
                  </Box> 
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <Typography variant="body1" align="left" gutterBottom className={classes.whiteText}>
                    <Trans i18nKey="home:subBanner.text2">
                      This assessment is completely <b>FREE</b> to take.
                    </Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box my={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIcon />}
                      disableElevation
                      size="medium"
                      onClick={handleClick}
                      className={classes.buttonRoot}
                    >
                        <Typography variant="h5">
                          {t('common:buttons.startAssessment')}
                
                        </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={6} className={classes.bannerIconMargin}>
              <Grid container spacing={1} justify="center" alignItems="center" style={{margin: 0,width: '100%',}}>
                <Grid item sm={12} md={5} className={classes.bannerIconContainer}>
                  
                  <img src={iconAssist} alt={t('subBanerIconAlt1')} className={classes.bannerIcon}></img>
                </Grid>
                <Grid item  md={2}>
                  {
                    isMobile ? <ArrowDownwardIcon className={classes.iconFont} color="primary" fontSize="small"/> : <ArrowForwardIcon className={classes.iconFont} color="primary" fontSize="large"/>
                  }
                </Grid>
                <Grid item sm={12}  md={5} className={classes.bannerIconContainer}>

                  <img src={iconWelder} alt={t('subBanerIconAlt2')} className={classes.bannerIcon}></img>

                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </Container>
        
        {/* {
          overlay ? 
          (<div className={`${classes.overlayContainer} ${scrolled? classes.scroll: null}`} >
            <Container maxWidth="lg">
              <div className={classes.overlayWrapper}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{color: 'white'}}>
                      Want to  test your knowledge to see how your skills align to Canadian standards?
                    </Typography>
                    <Typography variant="body1" color="primary" gutterBottom>
                      Take the <b>FREE</b> assessment to find out.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} >
                    <Box display="flex"  >
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        className={classes.buttonRoot}
                        onClick={handleClick}
                        >
                          <Typography variant="h5">
                          Start Assessment
                        </Typography>
                      </Button>
                        <CloseIcon fontSize="small" onClick={closeOverlay} className={classes.closeIcon}/>
                    </Box>

                  </Grid>

                </Grid>
                </div>
            </Container>      
          </div>):null
        } */}
        
      </section>
      
      

      <Container maxWidth='xl' className={classes.container}>
          <section>
        {/** Section 2 */}
        <div className={classes.sectionWrapper}>
          <Grid container justify="center" alignItems="flex-start" className={classes.section1Container}>

            <Grid item xs={12} md={6}>
              {/* <Paper className={classes.image1Styles} style={{ backgroundImage: `url(${bodyImage1})` }}>
                
              </Paper> */}
              <img className={classes.image} src={bodyImage1} alt={t('section1.imageAlt')} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box flexDirection="column" >
                  <div  className={classes.sectionHeader}>

                    <div className={classes.headerIconContainer}>
                      <img className={classes.headerIcon} src={iconBook} alt={t('section1.titleIconAlt')}/>
                    </div>

                    <div className={classes.headerTextContainer}>
                      <Typography variant="h2" align="left">
                        <Box >
                          {t('section1.header')}
                        </Box>
                      </Typography>
                    </div>
                  </div>
                  
                  <div className={classes.vertical_line_wrapper}>
                    <div className={classes.line_container}>
                      <div className={classes.vertical_dotted_line}></div>  
                      <img src={iconArrow} alt={t('section1.arrowIconAlt')} className={classes.downArrow}/>
                    </div>
                    <Box className={classes.bodyTextContainer} >
                      <Typography align="left" variant="body1">
                        {t('section1.text')}
                      </Typography>
                    </Box>
                  </div>
              </Box>
            </Grid>
          </Grid>
        </div>

        {/** End Section 1 */}
        </section>
        </Container>
        
      <Container maxWidth='xl' className={classes.container}>
        <section>
        {/** Section 2 */}
        <div className={classes.sectionWrapper}>
          <Grid container justify="center" alignItems="flex-start" className={classes.section1Container}>

            <Grid item xs={12} md={6}>
              {/* <Paper className={classes.image2Styles} style={{ backgroundImage: `url(${homeImage2})` }}>
                
              </Paper> */}
              <img className={classes.image} src={homeImage2} alt={t('section2.imageAlt')}></img>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box flexDirection="column" >
                  <div  className={classes.sectionHeader}>
                    <div className={classes.headerIconContainer}>
                      <img className={classes.headerIcon} src={iconBook} alt={t('section2.titleIconAlt')}/>
                    </div>
                    <div className={classes.headerTextContainer}>
                      <Typography variant="h2" align="left">
                        <Box >
                          {t('section2.header')}
                        </Box>
                      </Typography>
                    </div>
                  </div>
                  
                  <Box className={`${classes.bodyMargin} `} >
                    <Typography align="left" gutterBottom variant="body1">
                    {t('section2.text1')}
                  
                    </Typography>
                    <Typography align="left" variant="body1">
                    {t('section2.text2')}
            
                    </Typography>
                  </Box>

                  <Box className={classes.section2IconContainer}>
                      <ul className={classes.timelineContainer}>
                        
                        <li className={classes.timelineListItem}>
                          <div className={classes.line}>
                            <img src={iconProcess} alt={t('section2.timelineIconAlt1')} className={classes.timelineIcon} />
                          </div>
                          {/* <AddCircleIcon className={classes.timelineIcon} color="secondary" fontSize="large"/> */}
                          <Typography variant="body2" align="center">
                            
                              <b>{t('section2.graphicText1')}</b>
                        
                          </Typography>
                        </li>
                        <div className={classes.verticalLine}></div>

                        <li className={classes.timelineListItem}>
                          {/* <AddCircleIcon className={classes.timelineIcon} color="secondary" fontSize="large"/>  */}
                          <div className={classes.line}>
                            <img src={iconTrend} alt={t('section2.timelineIconAlt2')} className={classes.timelineIcon} />
                          </div>
                          <Typography variant="body2" align="center">
                      
                            <b>{t('section2.graphicText2')}</b>
                    
                          </Typography>
                        </li>

                        <div className={classes.verticalLine}></div>


                        <li className={classes.timelineListItem}>
                          {/* <AddCircleIcon className={classes.timelineIcon} color="secondary" fontSize="large"/> */}
                          <div className={classes.timelineIconContainer}>
                            <img src={iconInteractive} alt={t('section2.timelineIconAlt3')} className={classes.timelineIcon} />
                          </div>
                          <Typography variant="body2" align="center">
                  
                          <b>{t('section2.graphicText3')}</b>
                        
                          </Typography>
                        </li>
                      </ul>
                  </Box>

              </Box>
            </Grid>
          </Grid>
        </div>

        {/** End Section 2 */}
        </section>
      </Container>
      <Container maxWidth='xl' className={classes.container}>
        <section>
        
          <Grid container className={classes.section3Container}>
            
            <Grid item xs={12}>
              {/* <Paper className={classes.section3Image} style={{ backgroundImage: `url(${bodyImage2})` }}>
              </Paper> */}
            </Grid>
            <Grid item xs={12}>
          
              <Box py={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                disableElevation
                size="medium"
                component={Link}
                to={"/welder-competency-credential-assessment-program-information"}
                className={classes.buttonRoot}
              >
                  <Typography variant="h5">
                    <Trans i18nKey="home:bottomBtn">
                      For more details, go to  <b>&nbsp;Program Information</b>
                    </Trans>
                </Typography>
              </Button>
              </Box>
            </Grid>

          </Grid>
        </section>
      </Container>



    </>
  );
};

export default Home;