import React from 'react'
import { NavLink as Link, useLocation } from "react-router-dom";
import { 
    Typography, 
    List,
    ListItem,
    ListItemText,
    Box,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Container
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useAuth} from '../useAuth';
import iconCwbGroup from '../../assets/icons/icon-cwb-group.png';
import iconMenuSearch from '../../assets/icons/icon-menu-search.png';
import iconArrowDown from '../../assets/icons/icon-arrow-down.png';
import iconArrowUp from '../../assets/icons/icon-arrow-up.png';
import {useTranslation} from "react-i18next";
import LanguageSelect from './LanguageSelect';

const DesktopMenu = (props) => {
    let currentLocation = useLocation();
    const {location, setOpenPopup, language, languageOptions, handleLanguageChange} = props;
    const welderActive = location === '/international-welder-requirements-work-canada' || location === '/welder-requirements-across-canada' || location === '/welding-training-canada';
    const careerActive = location === '/search-welding-jobs-canada' || location === '/hire-welder';
    const classes = useStyles();
    const [anchorElWelder, setAnchorElWelder] = React.useState(null);
    const [anchorElCareer, setAnchorElCareer] = React.useState(null);
    const {user, signout, token} = useAuth();
    
    const { t } = useTranslation('common');

    const handleClickCareer = (event) => {
        setAnchorElCareer(event.currentTarget)
    };
    const handleCloseCareer = () => {
        setAnchorElCareer(null);
    };
    const handleClickWelder = (event) => {
        setAnchorElWelder(event.currentTarget);
    };
    const handleCloseWelder = () => {
        setAnchorElWelder(null);
    };    
    
    return (
        <Container maxWidth='xl' className={classes.wrapper}>
        <div className={classes.root}>
            <nav className={classes.container} >

                <Box>
                    <Link className={classes.link} to="/"> 
                        {/* <Typography variant="h6" className={classes.title}> */}
                            <img className={classes.logo} src={iconCwbGroup} alt="CWB Logo"/>
                        {/* </Typography> */}
                    </Link>
                </Box>

                <Box className={classes.linksWrapper} justifyContent="space-between" alignItems="center" flexWrap="nowrap">

                    <List className={classes.list} >
                        <ListItem  component={Link} to="/welder-competency-credential-assessment-program-information" className={`${classes.listItem} ${classes.link}` } activeStyle={{ borderBottom: '0.15rem solid #0C89BC' }}>
                            <ListItemText primary={t('topMenu.header1.title')} className={`${classes.listItemText}`}/>
                        </ListItem>
                        
                            <ListItem onClick={handleClickWelder} className={`${classes.listItem} ${welderActive ? classes.activeLink: null}`} aria-controls="simple-menu" aria-haspopup="true">
                                <ListItemText primary={t('topMenu.header2.title')} className={`${classes.listItemText}`}/>
                                {anchorElWelder ? <IconButton size="small"><img className={classes.iconArrow} src={iconArrowUp}/></IconButton> :<IconButton size="small"><img className={classes.iconArrow} src={iconArrowDown}/></IconButton>}
                            </ListItem>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorElWelder}
                                getContentAnchorEl={null}
                                keepMounted
                                open={Boolean(anchorElWelder)}
                                onClose={handleCloseWelder}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                
                            >
                                <MenuItem  onClick={handleCloseWelder} component={Link} to="/international-welder-requirements-work-canada" className={`${classes.link} ${classes.menuLink}`}>
                                    {/* <Link className={`${classes.link} ${classes.menuLink}`} to="/internationalWelders" >  
                                            International Welders
                                    </Link>     */}
                                    {t('topMenu.header2.subHeader1')}
                                </MenuItem>
                                <MenuItem onClick={handleCloseWelder} component={Link} to="/welder-requirements-across-canada" className={`${classes.link} ${classes.menuLink}`}>
                                    {/* <Link className={`${classes.link} ${classes.menuLink}`} to="/provinceToProvince" >  
                                            Province to Province
                                    </Link> */}
                                    {t('topMenu.header2.subHeader2')}
                                </MenuItem>
                                <MenuItem onClick={handleCloseWelder} component={Link} to="/welding-training-canada" className={`${classes.link} ${classes.menuLink}`}>
                                    {/* <Link className={`${classes.link} ${classes.menuLink}`} to="/weldingTraining" >  
                                        Welding Training in Canada
                                    </Link> */}
                                    {t('topMenu.header2.subHeader3')}
                                </MenuItem>
                            </Menu>
                        

                        <ListItem  component={Link} to="/welders-certifications-requirements-canada" className={`${classes.listItem} ${classes.link}`} activeStyle={{ borderBottom: '0.15rem solid #0C89BC' }}>
                            <ListItemText primary={t('topMenu.header3.title')} className={`${classes.listItemText}`}/>
                        </ListItem>
                        <ListItem  component={Link} to="/welder-competency-credential-assessment-information" className={`${classes.listItem} ${classes.link}`} activeStyle={{ borderBottom: '0.15rem solid #0C89BC' }}>
                            <ListItemText primary={t('topMenu.header4.title')} className={`${classes.listItemText}`}/>
                        </ListItem>

                        
                            <ListItem onClick={handleClickCareer} className={`${classes.listItem} ${careerActive ? classes.activeLink: null}`}>
                                <ListItemText primary={t('topMenu.header5.title')} className={`${classes.listItemText}`}/>
                                {anchorElCareer ? <IconButton size="small"><img className={classes.iconArrow} src={iconArrowUp}/></IconButton> :<IconButton size="small"><img className={classes.iconArrow} src={iconArrowDown}/></IconButton>}
                            </ListItem>
                            <Menu
                                anchorEl={anchorElCareer}
                                getContentAnchorEl={null}
                                keepMounted
                                open={Boolean(anchorElCareer)}
                                onClose={handleCloseCareer}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                
                            >
                                <MenuItem  onClick={handleCloseCareer}  component={Link} to="/search-welding-jobs-canada" className={`${classes.link} ${classes.menuLink}`}>
                                    {t('topMenu.header5.subHeader1')}
                                </MenuItem>
                                {/** DISABLED HIRE A WELDER */}
                                {/* <MenuItem onClick={handleCloseCareer}  component={Link} to="/hireWelder" className={`${classes.link} ${classes.menuLink}`}>
                                    Hire a Welder
                                </MenuItem> */}
                            </Menu>
                        
                            
                    </List>
                </Box>

                <Box className={classes.buttonWrapper} display="flex" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                    {
                        token ? (
                            <Button 
                                color="primary" 
                                variant="contained" 
                                disableElevation 
                                className={`${classes.itemMargin} ${classes.buttonRoot}`}
                                onClick={() => signout()}
                            >
                                <b>{t('topMenu.logoutBtn')}</b>
                            </Button>
                        ) : (
                            <Button 
                                color="primary" 
                                variant="contained" 
                                disableElevation 
                                className={classes.buttonRoot}
                                component={Link}
                                // onClick={() => setOpenPopup(true)}
                                to={{
                                    pathname: "/signup",
                                    state: { background: currentLocation }
                                }}
                            >
                                <b>{t('topMenu.signupBtn')}</b>
                            </Button>
                        )
                    }
                    
                    <div className={`${classes.itemMargin} ${classes.lanContainer}`}>
                        <LanguageSelect 
                            language={language}
                            handleLanguageChange={handleLanguageChange}
                            languageOptions={languageOptions}
                        />
                    </div>
                    {/* <img src={iconMenuSearch} alt="Search Icon" className={classes.itemMargin}/> */}
                </Box>
            </nav>
        </div>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingRight:'0px',
        paddingLeft: '0px'
    },
    list: {
        display:'flex'
    },
    listItem: {
        paddingBottom: '0',
        paddingRight: 0,
        width: 'auto',
        '&::after': {
            content: '"|"',
            float: 'right',
            color: theme.palette.secondary.main,
            paddingLeft: theme.spacing(1)
        },
        '&:last-child::after': {
            display: 'none'
        }

    },
    listItemText: {
        cursor: 'pointer',
        '& span' :{
            textAlign:'center',
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: 1
        }
        
    },
    menuLink: {
        fontSize: '20px',
    },
    expandedListItem: {

    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // padding: theme.spacing(0, 4),  
    },
    link: {
        color: "black",
        textDecoration: "none",
        // margin: theme.spacing(1, 1.5),
        '&:hover': {
              textDecoration: 'none',
              color: 'black'
          }
    },
    lanContainer:{
        fontSize:'16px',
    },
    language: {
        color: "black",
        textDecoration: "none",
    },
    root: {
      flexGrow: 1,
      padding:theme.spacing(1, 0)
    },
    title: {
      flexGrow: 1,
    },
    
    itemMargin: {
        margin: theme.spacing(0, 1.5)
    },
    logo: {
        height: '120px',
        width:'120px'

    },
    activeLink: {
        borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
    },
    buttonRoot: {
        fontSize:'20px',
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '8px',
        margin: theme.spacing(0, 1.5),
        lineHeight: 1,
        textAlign: 'center'
    },
    iconArrow: {
        width: '20px',
        height: '20px'
    },
    linkUnderline: {
        borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
    }
  }));

export default DesktopMenu
