import React from 'react'
import { 

    Typography,
    Box
} from '@material-ui/core';


const WelderBox = ({
    jc="center",
    title="",
    title2="", 
    subtitle="", 
    secondaryTitle="", 
    secondarySubtitle="", 
    secondarySubtitle2="", 
    hours="", 
    mdCols=3, 
    maxWidth="210px",
    children}
) => {
    return (
       
        <Box  
            p={1} 
            minHeight="150px"
            maxWidth={maxWidth} 
            mx="auto" 
            borderRadius="1rem" 
            overflow="hidden" 
            bgcolor="secondary.light"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={jc}
        >
            { !children ? (
                <>
                <Box p={1}>
                    { title &&
                    <Box>
                        <Typography variant="body1" align="center">
                            <b>{title}</b>   
                        </Typography>
                        {title2 &&
                            <> 
                            <Box my={2}>
                                <Typography variant="body1" align="center">
                                    <b>OR</b>   
                                </Typography>
                                
                            </Box>
                            <Box>
                            <Typography variant="body1" align="center">
                                <b>{title2}</b>   
                            </Typography>
                            </Box>
                            </>
                                    
                        }
                    </Box>
                    }
                    { subtitle &&
                    <Typography variant="body1" align="center">
                        {subtitle}
                    </Typography>
                    }
                </Box>
                {(secondaryTitle || secondarySubtitle || hours) &&
                <Box p={1} bgcolor="common.white" borderRadius="1rem">
                    <Typography variant="body1" align="center">
                        {secondaryTitle}
                        <br/>
                        {secondarySubtitle}
                    </Typography>
                    {secondarySubtitle2 &&
                        <>
                            <Box my={2}>
                                <Typography variant="body1" align="center">
                                    <b>OR</b>   
                                </Typography>
                                
                            </Box>
                            <Typography variant="body1" align="center">
                                {secondarySubtitle2} 
                            </Typography>
                        </>
                    }
                    <Typography variant="body1" align="center">
                        <b>{hours}</b>
                    </Typography>
                    <Typography>

                    </Typography>
                </Box>
                }
                </>
            ): children} 
        </Box>
    )
}

export default WelderBox
