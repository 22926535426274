  
import React from "react";
import { Grid, Typography, Box, Paper, Button, Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Banner from '../components/Banner';
import ListSection from '../components/ListSection';
import iconInternational from '../assets/icons/3-province.png';
import iconDo from '../assets/icons/icon-do.png';
import iconNewcomer from '../assets/icons/icon-newcomer.png';
import provinceHeroDesktop from '../assets/province/province-header-desktop.png';
import provinceHeroMobile from '../assets/province/province-header-mobile.png';
import bodyImage1 from '../assets/province/image9.png';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";



const useStyles = makeStyles((theme) => ({

    padding: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]:{
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        }
    },
    paddingSmall: {
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },
    section:{
        marginBottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(7),
        },
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderTopRightRadius: '1rem',
        borderBottomRightRadius: '1rem',
        [theme.breakpoints.down('sm')]: {
            borderTopRightRadius:0,
            borderBottomRightRadius: 0,
        },
    },
    headerIconContainer: {
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        height: '40px',
        width: '40px',
    },
    headerTextContainer: {},
    imageSection: {
        padding: theme.spacing(0, 8),
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column-reverse',
            padding: theme.spacing(0, 0),
        }
    },
    imageContainer: {
        width: '100%',
    },
    // image: {
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     height: '200px',
    //     width:'100%',
    //     margin: '0 auto',
    //     borderRadius: '1rem',
    //     [theme.breakpoints.down('sm')]: {
    //         borderRadius: 0
    //     },
    // },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
        
    },
    container: {
    
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          padding: 0,
          // justifyContent: 'center',
        }
    },
    link: {
        color: theme.palette.secondary.main
    },
    image: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: 'center'
    }

}));

const ProvinceToProvince = () => {
const classes = useStyles();
const { t } = useTranslation(['provinceToProvince', 'common']);

const listSections = [
    {
        title: t('section2.table.item1.title'),
        body: t('section2.table.item1.body'),
        link: 'https://www.cfta-alec.ca/wp-content/uploads/2017/06/CFTA-Consolidated-Text-Final-Print-Text-English.pdf'
    },
    {
        title: t('section2.table.item2.title'),
        body: t('section2.table.item2.body'),
        link: 'http://workersmobility.ca/labour-mobility/labour-mobility-resources/'
    },
    {
        title: t('section2.table.item3.title'),
        body: t('section2.table.item3.body'),
        link: 'https://www.canada.ca/en/employment-social-development/programs/foreign-credential-recognition.html'
    },
    {
        title: t('section2.table.item4.title'),
        body: t('section2.table.item4.body'),
        link: 'https://www.canada.ca/en/employment-social-development/campaigns/skilled-trades.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_skilled-trades'
    },
    
]

  return (
    <>
    <Helmet>
        <title>
            {t('metaTitle')}
        </title>
        <meta name="description" content={t('metaDesc')}/>
    </Helmet>
    {/** Banner */}
    <Banner 
        src={provinceHeroMobile} 
        srcset={provinceHeroDesktop} 
        alt={''} 
        iconSrc={iconInternational} 
        iconAlt={t('bannerIconAlt')}
        title={t('banner')}
        jc={'center'}
    />
    <Container maxWidth="xl" className={classes.container}>
    <section className={classes.section}>
        <Grid container>
            <Grid item xs={12} md={10} className={`${classes.headerContainer} ${classes.padding}`}>
            
                <div className={classes.headerIconContainer}>
                    <img className={classes.headerIcon} src={iconDo} alt={t('section1.headerIconAlt')}/>
                </div>

                <div className={classes.headerTextContainer}>
                    <Typography variant="h2" align="left">
                        <Box>
                            {t('section1.header')}
                        </Box>
                    </Typography>
                </div>
        
            </Grid>
        </Grid>
     
            <Box mt={4}>
                <Grid container justify="center" alignItems="flex-start" spacing={3} className={`${classes.imageSection}`} style={{margin: 0,width: '100%',}}> 
                    
                    <Grid item xs={12} md={8}>
                        <div className={` ${classes.paddingSmall}`}>
                            <Typography variant="body1" color="inherit" align="left" gutterBottom>
                            <Trans i18nKey="provinceToProvince:section1.text">
                                If you are already certified to work in a regulated occupation in your province or territory, 
                                it will be easier for you to have your certification recognized in another province or territory. 
                                See the <b>Workers Mobility's</b> website to learn more to see detailed information for all provinces and territories. 
                                You can also find out about your Labour Mobility Coordinator under Contact on the&nbsp;
                            </Trans>
                              
                            <Link 
                                className={classes.link} 
                                to={{
                                    pathname: "/regulation",
                                    state: { scrollToMap: true }
                                }}
                            >
                               {t('section1.map')}
                            </Link>
                            </Typography>
                            <Box mt={2}>
                            <Button 
                                color="primary" 
                                size="small" 
                                variant="contained" 
                                target="_blank" 
                                href="http://www.workersmobility.ca/"
                                disableElevation
                                className={classes.buttonRoot}
                            >
                                <Typography variant="h5">
                                {t('common:buttons.viewWebsite')}
                                </Typography>
                            </Button>
                            </Box>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} md={4} className={classes.imageContainer}>
                        {/* <Paper className={`${classes.image}`} style={{backgroundImage: `url(${bodyImage1})`}}>
                        </Paper> */}
                        <img className={classes.image} src={bodyImage1} alt={t('section1.imageAlt')}/>
                    </Grid>
                    
                </Grid>
                
            </Box>
    
    </section>
    <section className={classes.section}>
      <Grid container>
            <Grid item xs={12} md={6} className={`${classes.headerContainer} ${classes.padding}`}>
            
                <div className={classes.headerIconContainer}>
                    <img className={classes.headerIcon} src={iconNewcomer} alt={t('section2.headerIconAlt')}/>
                </div>

                <div className={classes.headerTextContainer}>
                    <Typography variant="h2" align="left">
                        {t('section2.header')}
                    </Typography>
                </div>
        
            </Grid>
        </Grid>
        <Box mt={4} className={`${classes.padding}`}>
            <ListSection items={listSections}/>
        </Box>
      </section>
    </Container>
    </>
  );
};

export default ProvinceToProvince;