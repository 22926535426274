import React from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { 
    makeStyles,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            transform: `rotate(90deg)`
        }
    }
}));
const ArrowIcon = () => {
    const classes= useStyles();
    return (
        <ArrowForwardIcon fontSize="large" className={classes.root} color="secondary"/>
    )
}

export default ArrowIcon
