import React, { useEffect,} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Container, CircularProgress, Backdrop, Button} from "@material-ui/core";
import {useAuth} from '../components/useAuth';
import {useHistory, Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    iframeContainer: {
        height: '100%'
    },
    backdrop: {
        zIndex: 1000,
        backgroundColor: `rgb(255, 255, 255, 1)`
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin: theme.spacing(2, 0),
        width: '200px',
        textAlign:'center',
    },
    redText: {
        color:'red',
    }

}));
const StartAssessment = (props) => {
    const classes = useStyles();
    const iframeRef = React.createRef();
    const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [overlayOpen, setOverlayOpen] = React.useState(false);
    const [quizStart, setQuizStart] = React.useState(false);
    const {token, user} = useAuth();
    const { t,  i18n} = useTranslation(['startAssessment', 'error']);
    let history = useHistory();
    let quizLink='';
    
    if(user) {
        if (i18n.language.substr(0, 2) === 'en') {
            quizLink = ``;
        } else if (i18n.language.substr(0, 2) === 'fr') {
            quizLink = ``;
        } else if (i18n.language.substr(0, 2) === 'uk') {
            quizLink = ``;
        }
    }
    const handleIframeLoad = () => {
        setLoading(false);
    }

    useEffect(() => {
        const iframeElement = iframeRef && iframeRef.current;
        if(iframeElement) {
            if(user) {
                if (i18n.language.substr(0, 2) === 'en') {
                    iframeElement.src = ``;
                } else if (i18n.language.substr(0, 2) === 'fr') {
                    iframeElement.src = ``;
                } else if (i18n.language.substr(0, 2) === 'uk') {
                    iframeElement.src = ``;
                } 
            }
            setTimeout(() => {
                if(iframeElement.contentWindow) {
                    iframeElement.contentWindow.postMessage({message: 'language', language:i18n.language}, '*');
                }
            }, 600)
            
        }

    }, [i18n.language])

    useEffect(() => {
        const iframeElement = iframeRef && iframeRef.current;
        if(iframeElement && iframeElement.contentWindow) {
            iframeElement.contentWindow.postMessage({message: 'language', language:i18n.language}, '*');
        }
    }, [iframeRef])

    useEffect(() => {
        const handler = e => {
           
            if (e.data === 'submit') {
                setOverlayOpen(true)
                setTimeout(() => {
                    history.push({
                        pathname: "/result",
                        state: { data: userData }
                    });

                }, 1100)  

            }
            if (e.data === 'start-quiz') {
                setQuizStart(true);
            }
        }
        window.addEventListener("message", handler)
    
        // clean up
        return () => window.removeEventListener("message", handler)
      }, [userData]) // empty array => run only once
    return (
        <>
        {
            token && user ? (
            
               
            <Container maxWidth="xl" className={classes.container}>
                <>
                    <Box my={3}>
                        {/* <Typography variant="h1" align="center" gutterBottom>
                            {t('title')}
                        </Typography> */}
                    </Box>

                    {

                        // loading && 
                        // <Box display="flex" justifyContent="center">
                        //     <CircularProgress color="secondary" />
                        // </Box>
                    }


                    {
                        // <Backdrop className={classes.backdrop} open={overlayOpen}>
                        //     <CircularProgress color="secondary" />
                        // </Backdrop>
                    }
                    {
                        
                        <Box  my={3} className={classes.iframeContainer}>
                            {/*http://translate.google.com/translate?hl=bg&ie=UTF-8&u=https://myquizscape.com/quizzes/5fbffa707aaac809c9d00728/take?email=mm@gmail.com&user_name=mm@gmail.com&platform_user_id=96905 */}
                            {/* <iframe
                                ref={iframeRef} */}
                                {/* // src="https://myquizscape.com/quizzes/5fda798e7aaac85c33d8635d/take"
                                // src={"https://myquizscape-com.translate.goog/quizzes/5fbffa707aaac809c9d00728/take?_x_tr_sl=de&_x_tr_tl=bg&_x_tr_hl=bg"}                             
                                // src={"https://translate.google.com/translate?hl=bg&ie=UTF-8&u=https://myquizscape.com/quizzes/5fbffa707aaac809c9d00728/take&sl=de&tl=bg"}

                                // src="https://myquizscape.com/quizzes/5fda798e7aaac85c33d8635d/take" */}
                                {/* src={quizLink}
                                title={t('title')}
                                height={500}
                                width="100%"
                                frameBorder="0"
                                onLoad={ handleIframeLoad}
                            >

                            </iframe> */}
                            This webpage is currently being upgraded and will be relaunched later in summer 2024 as part of the Welder’s Competency Assessment and Skills Training (WCAST) program. For more information on the WCAST program, please visit <a href='https://www.cwbgroup.org/'>CWB Group Launches Full-Cycle Assessment and Training Program</a> or contact us at <a href="mailto:WCAST.CWB@cwbgroup.org">WCAST.CWB@cwbgroup.org</a>
                            <Box my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {/* <Typography variant="body1" align="center" color="secondary" gutterBottom>
                                    <em>{t('text1')}</em>
                                </Typography> */}
                                {
                                    quizStart &&
                                    <Button 
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        component={Link}
                                        to={{ 
                                            pathname: '/welder-competency-credential-assessment-information',
                                            state: { scrollToStartAssessment: true }
                                        }}
                                        className={classes.buttonRoot}
                                    >
                                        <Typography variant="h5">
                                            {t('buttonText')}
                                        </Typography>
                                    </Button>
                                }
                                

                            </Box>
                        </Box>
                    }
                    
                </>   
            </Container>
            ) : (
            <Container maxWidth="lg">
                <Box my={3}>
                    <Typography variant="h3" align="center">
                        {t('error:loginRequired')}
                    </Typography>
                </Box>
            </Container>
            )
        }
        
        </>
    )
}

export default StartAssessment
