import React from 'react'
import { Typography, Box, Button, Card, CardActions, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    // listItem: {
    //     listStyleType: 'lower-alpha'
    // },
    // container: {
    //     position:'fixed',
    //     bottom: 0,
    //     backgroundColor: '#000',
    //     opacity: 0.9,
    //     width: '100%',
    //     padding: theme.spacing(2),
    //     zIndex: '100'
    // }
}));

const CookieConsent = (props) => {
    const classes= useStyles();
    const { setOpen } = props;
    const { t } = useTranslation('common');
    const handleClick = () => {
        setOpen(false)
    }

    return (
       
            <Box >
                <Box mb={4}>
                    <Typography variant="body2" gutterBottom >
                        {t('cookieModal.text')}
                    </Typography>
                </Box>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="contained" 
                    disableElevation
                    className={classes.buttonRoot}
                    onClick={handleClick}
                >
                    {t('cookieModal.buttonText')}
                </Button>
            </Box>
    
    )
}

export default CookieConsent
