import React from 'react'
import Banner from '../components/Banner';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Container } from '@material-ui/core';
import hireHeroMobile from '../assets/hireWelder/hire-hero-mobile.png';
import hireHeroDesktop from '../assets/hireWelder/hire-hero-desktop.png';
import iconInternational from '../assets/icons/11-Hiring-Welder.png';
const useStyles = makeStyles((theme) => ({
    bodyContainer: {
      paddingLeft: '64px',
      paddingRight: '64px',
      [theme.breakpoints.down('sm')]: {
          padding: 0
      }
    },  
    paddingSmall: {
      [theme.breakpoints.down('sm')]:{
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      }
    },
    padding: {
        paddingLeft: '64px',
        paddingRight: '64px',
        [theme.breakpoints.down('sm')]:{
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }
    },
    section:{
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    container: {
    
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: 0,
        // justifyContent: 'center',
      }
  },
}));


const HireWelder = () => {
    const classes = useStyles();
    return (
        <>
            <Banner 
                src={hireHeroMobile} 
                srcset={hireHeroDesktop} 
                alt={'Hire a welder'} 
                iconSrc={iconInternational} 
                iconAlt={'international'} 
                title={'Hire a Welder'}
                jc={'center'}
            />
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.padding}>
            <Box mb={2}>
            <Typography variant="body2" color="inherit" gutterBottom align="left" className={classes.paddingSmall}>
                
                    Hire a Welder
               
            </Typography>
            </Box>
            </div>
            </Container>
        </>
    )
}

export default HireWelder
