import React from "react";
import { Grid, Typography, Container, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    container: {
        // height: '100vh',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3) 
    },
    section: {
        marginTop: theme.spacing(3) 
    }
}))
const TermsAndConditions = () => {
    const classes = useStyles();
    const { t } = useTranslation('terms');
    return (
        <>
        <Helmet>
                <title>
                    {t('metaTitle')}
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Container maxWidth="xl" className={classes.container}>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h1" align="left" gutterBottom>
                            {t('title')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section1.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section1.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section1.text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section1.text3')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section2.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section2.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section2.text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section3.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section3.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section3.text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section4.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="terms:section4.text1">
                                Notifications of any unlawful conduct, including, without limitation, copyright or other intellectual property infringement should be sent to CWB Group’s designated agent at <a href="mailto:webmaster@cwbgroup.org">webmaster@cwbgroup.org</a>.
                            </Trans>
                        


                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section4.text2')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section5.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section5.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="terms:section5.text2">
                                The Site Content is protected by Canadian and other international copyright and trade-mark laws. All Site Content, including the collection, arrangement, and assembly of such content, is the exclusive property of CWB Group, unless otherwise identified. You may not copy, sell, reproduce, distribute, retransmit, publish, modify, display, prepare derivative works based on, re-post or otherwise use any of the Site Content in any way for any public or commercial purpose without the prior written consent of CWB Group. You may display on your computer, download, use and make one printed copy of the Site Content for your own private, non-commercial use, so long as you do not modify any such Site Content; include the following copyright notice: “Copyright 2003, CWB Group All rights reserved”.; and any other copyright or trade-mark notices contained in the specific Site Content are retained. You acknowledge and agree that nothing in this Terms of Use confers any ownership rights in the Site Content from CWB Group. Should you wish to use Site Content for purposes other than your personal use, or to modify the Site Content, you must first contact CWB Group by sending an e-mail to <a href="mailto:webmaster@cwbgroup.org">webmaster@cwbgroup.org</a> requesting our permission to use the Site Content, fully advising us of your intended use and obtain our written consent prior to such use.
                            </Trans>
                        
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section5.text3')}

                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section5.text4')}


                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section5.text5')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section6.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section6.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section6.text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section6.text3')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section7.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section7.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section8.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section8.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section9.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section9.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section10.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section10.text1')}
                        </Typography>
                    </Box>
                </section>

                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section11.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section11.text1')}
                        </Typography>
                    </Box>
                </section>
            </Container>
        </>
    );
};

export default TermsAndConditions;