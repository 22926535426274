import React from 'react'
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link as ScrollLink } from 'react-scroll';
import trainingImage4 from '../../assets/training/training-image4.png';
import trainingImage5 from '../../assets/training/training-image5.png';
import trainingImage6 from '../../assets/training/training-image6.png';
import trainingImage7 from '../../assets/training/training-image7.png';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    listImage: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'100%',
        margin: '0 auto',
        
    },
    sectionImage: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'100%',
        margin: '0 auto'
    },
    paddingSmall: {
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
          paddingLeft: '12px',
        }
    },

    imageSection: {
        [theme.breakpoints.down('xs')]:{
          flexDirection: 'column-reverse',
        }
    },
    imageContainer: {
        width: '100%',
    },
    image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '300px',
        width:'100%',
         margin: '0 auto',
        borderRadius: '1rem',
        [theme.breakpoints.down('sm')]: {
         borderRadius: 0
        },
    },
    fullWidth: {width: '100%'},
    bodyImage: {
        width: '100%',
        height: 'auto',
        
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin:'0 auto',
    },
}));


const ListItem = ({section}) => {
    const classes = useStyles();
    const {url, title, body, alt} = section;
    return (
        <Grid item className={classes.fullWidth}>
            <Box mb={3}>
            <Typography variant="h3" color="secondary" gutterBottom >
              {title}
            </Typography>
            </Box>
            <Grid container justify="center" align="flex-start" spacing={3} className={` ${classes.imageSection}`}>
                
                <Grid item xs={12} md={6}>
              
                    <Typography variant="body1" align="left" paragraph>
                        {body}
                    </Typography>
                    {
                        section.internalLink &&
                    <Box my={4}>
                        <Button
                        color="primary" 
                        size="small"
                        variant="contained" 
                        disableElevation
                        className={classes.buttonRoot}
                        component={ScrollLink} 
                        to={section.internalLink.to}
                        >
                            <Typography variant="h5">
                            {section.internalLink.title}
                            </Typography>
                        </Button>
                    </Box>
                    }   
              
                </Grid>
                <Grid item xs={12} md={6} className={classes.imageContainer}>
                    {/* <div className={classes.listImage} style={{backgroundImage: `url(${url})`}}></div> */}
                    <img src={url} alt={alt}  className={classes.bodyImage}/>
                </Grid>
                
            </Grid>

        </Grid>
    ); 
}

const CredentialSection = () => {
    const classes = useStyles();
    const { t } = useTranslation('weldingTraining');
    const listSections = [
        {
            url: trainingImage4,
            alt: t('section3.item1.imageAlt'),
            title: t('section3.item1.title'),
            body: t('section3.item1.text'),
            // internalLink: {
            //     to: 'School',
            //     title: 'Go to School Information'
            // }
        },
        {
            url: trainingImage5,
            alt:  t('section3.item2.imageAlt'),
            title: t('section3.item2.title'),
            body: t('section3.item2.text'),
        },
        {
            url: trainingImage6,
            alt: t('section3.item3.imageAlt'),
            title: t('section3.item3.title'),
            body: t('section3.item3.text'),
        }
    ]
    return (
        <>
            <div className={classes.paddingSmall} id="Credentials">
                <Box mb={3}>
                    <Typography variant="h2" align="left" gutterBottom>
                        {t('section3.header')}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography variant="body1" align="left" gutterBottom>   
                        {t('section3.text1')}
                    </Typography>
                </Box>
            
                <Grid container spacing={4}>
                    {listSections.map((section, idx) => 
                        <ListItem 
                            key={idx}
                            section={section}

                        />
                    )}
                </Grid>
                <Box mt={3} mb={6}>
                    <Typography variant="body1" align="left" gutterBottom>
                        {t('section3.text2')}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography variant="h3" color="secondary" align="left" gutterBottom >
                        {t('section3.item4.title')}
                    </Typography>
                </Box>
                <Grid container spacing={3} justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" color="inherit" align="left">
                            {t('section3.item4.text')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={trainingImage7} alt={t('section3.item4.imageAlt')} className={classes.bodyImage}/>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default CredentialSection;
