import React from 'react'
import { makeStyles, Paper, Grid, Typography, Box, Button, useMediaQuery, Container, Tabs, Tab,SvgIcon } from "@material-ui/core";
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import FacebookIcon from '@material-ui/icons/Facebook';
import {useTranslation} from "react-i18next";
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}

        </div>
    );
}

const Signup = (props) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const { t } = useTranslation('common');
    return (
        <>
            <Container maxWidth="sm">

                <div className={classes.tabContainer}>
                    <Paper className={classes.content} elevation={0}>
                        <Tabs
                            value={value}
                            
                            textColor="inherit"
                            onChange={handleChange}
                            className={classes.tabs}
                            TabIndicatorProps={{className: classes.indicator}}
                        >
                            <Tab label={t('loginForm.tabs.login')} />
                            <Tab label={t('loginForm.tabs.signup')}  />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <LoginForm {...props}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <SignupForm {...props}/>
                        </TabPanel>
                    </Paper>

                    {/* <Box my={2} fontWeight="fontWeightBold">
                        or Login With
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <Button className={classes.buttonSocial}>
                            <FacebookIcon />    
                        </Button>   
                        <Button className={classes.buttonSocial}>
                        <SvgIcon >
                            <path fill="currentColor" d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
                        </SvgIcon>
                        </Button>    
                    </Box>      */}

                </div>
            </Container>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh'
    },
    content: {
        // margin: theme.spacing(5),
        // padding: theme.spacing(3),
       
    },

    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    indicator: {
        // backgroundColor: theme.palette.secondary.main,
        // color: 'white'
    },
    tabs: {
        '& .MuiTabs-flexContainer':{
            justifyContent: 'space-around'
        },
        '& .Mui-selected': {
            //backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
        }
    },

}));

export default Signup