import React, {useState} from 'react'
import { makeStyles, Grid, Typography, Box, Button, useMediaQuery} from "@material-ui/core";
import Input from '../../components/Input';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';

import {useForm, Form} from '../../components/useForm';
import Countries from './countries';
import { useAuth } from '../../components/useAuth';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useHistory, useLocation, Link} from 'react-router-dom';
import {useTranslation, Trans} from "react-i18next";


const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    selectedCountry: '',
    agreeTerms: false,
    qualification: '',
    optInEmail: false
}

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}));


const SignupForm = (props) => {
    const classes = useStyles();
    const {setOpenPopup, setOpenSignupConfirm} = props;
    const {signup} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    let location = useLocation();
    let history = useHistory();
    const { t } = useTranslation(['common', 'error']);

    const validate = () => {
        let temp = {}
        const re = /\S+@\S+\.\S+/
        temp.email = re.test(values.email) ? "" : t('error:emailInvalid')
        temp.agreeTerms = values.agreeTerms ? "" : t('error:fieldRequired') 
        if (values.password.length !== 0) {
            if(values.password.length > 5) {
                temp.password = ""
            } else {
                temp.password = t('error:minCharacters')
            }
        } else {
            temp.password = t('error:fieldRequired')
        }

        if (values.password !== values.confirmPassword) {
            temp.password = t('error:passwordMismatch')
            temp.confirmPassword = t('error:passwordMismatch')
        }
        if (values.selectedCountry === "" || values.selectedCountry === null) {
            temp.selectedCountry = t('error:fieldRequired')
        }

        setErrors({
            ...temp
        })

        //return Object.values(temp).every(x => x === "")

        var tempError = Object.keys(temp).map(function(key){
            return temp[key]
        });
        
        return tempError.every(x => x === "")
    }

    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
       

        if(validate()){
            setSubmitting(true);
            const selectedCountryLabel = Countries.find(item => item.code === values.selectedCountry).label;

            signup(values.email, values.password, selectedCountryLabel, values.agreeTerms, values.qualification, values.optInEmail)
            .then(() => {
                setSubmitting(false);
                history.push({
                    pathname: '/signup-confirm',
                    state: {background: {hash: "", pathname: location.state.background.pathname, search: "", state: undefined}}
                })
                //  setOpenPopup(false);
                //  setOpenSignupConfirm(true);
            })
            .catch(err => {
                
                setFormError(t('error:signupFailed'));
                setSubmitting(false);
            });
        }
    }
    const label = (
        <span>{t('loginForm.agreeText')}&nbsp;
        <Link
          to="/terms-and-conditions"
          target="_blank"
        >
            {t('loginForm.termsAndConditions')}
        </Link>
      </span>
    )
    return (
        <div>
            <Form onSubmit={handleSubmit}>

                {
                    formError &&
                    <Box my={3} p={1} bgcolor="secondary.light">
                        <Typography variant="subtitle2" color="error">
                            {formError}
                        </Typography>
                    </Box>
                }

                <Input  
                    label={t('loginForm.fields.email')}
                    name="email"
                    value={values.email}
                    onChange= {handleInputChange}
                    error={errors.email}
                />

                <Input
                    label={t('loginForm.fields.password')}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange= {handleInputChange}
                    error={errors.password}
                />
                <Input
                    label={t('loginForm.fields.confirmPassword')}
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange= {handleInputChange}
                    error={errors.confirmPassword}
                />
                
                <Select 
                    label={t('loginForm.fields.selectCountry')}
                    name="selectedCountry"
                    value={values.selectedCountry}
                    onChange= {handleInputChange}
                    options={Countries}
                    error={errors.selectedCountry}
                />

                <Input
                    label={t('loginForm.fields.qualification')}
                    name="qualification"
                    type="text"
                    multiline
                    inputProps={{ maxLength: 100 }}
                    value={values.qualification}
                    onChange= {handleInputChange}
                />
                <Checkbox 
                    name="agreeTerms"
                    label={label}
                    value={values.agreeTerms}
                    onChange= {handleInputChange}
                    error={errors.agreeTerms}
                />
                <Checkbox 
                    name="optInEmail"
                    label="I would like to receive marketing emails and future information."
                    value={values.optInEmail}
                    onChange= {handleInputChange}
                    error={errors.optInEmail}
                />

                <Box mx={1} my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Button 
                        color="primary" 
                        size="large" 
                        variant="contained" 
                        disableElevation
                        className={classes.buttonRoot}
                        endIcon={<ArrowForwardIcon />}
                        type="submit"
                        disabled={submitting}
                    >
                       {t('loginForm.signupButton')}
                    </Button>
                </Box>
           
            </Form>
        </div>
    )
}

export default SignupForm
