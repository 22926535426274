import React, {useState, useEffect} from "react";
import { 
    Grid, 
    Container,
    Typography, 
    Box,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    Link,
    Avatar,
    useMediaQuery,
    IconButton,
    Collapse,
    Button
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Banner from '../../components/Banner';
import useTable from '../../components/useTable';
import MapList from './MapList';
import MapResult from './MapResult';
import Map from './Map';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Select from '../../components/Select';
import {provinces, getProvinceFromlabel,getProvinceFromId, getIndexFromProvinceId} from './provinces';
import regulationHeroDeskTop from '../../assets/regulation/regulation-header-desktop.png';
import regulationHeroMobile from '../../assets/regulation/regulation-header-mobile.png';
import bodyImage1 from '../../assets/regulation/regulation-image1.png';
import bodyImage2 from '../../assets/regulation/regulation-image2.png';
import iconInternational from '../../assets/icons/6-Regulation.png';
import iconDo from '../../assets/icons/7-Regulation.png';
import iconSearch from '../../assets/icons/8-Educational.png';
import iconCertif from '../../assets/icons/icon-certif.png';
import { Link as ScrollLink, Element, scroller } from 'react-scroll';
import {useLocation} from 'react-router-dom';
import ScrollTopButton from "../../components/ScrollTopButton";
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";
const useStyles = makeStyles((theme) => ({
    section:{
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0),
        },
    },
    sidebar: {
        position: 'sticky',
        top: '10px'
    },
   
    padding: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },
    paddingSmall: {
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderTopRightRadius: '1rem',
        borderBottomRightRadius: '1rem',
        [theme.breakpoints.down('sm')]: {
            borderTopRightRadius:0,
            borderBottomRightRadius: 0,
        },
    },
    headerIconContainer: {
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        height: '40px',
        width: '40px',
    },

    imageSection: {
        padding: theme.spacing(0, 8),
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column-reverse',
            padding: theme.spacing(0, 0),
        }
    },
    paddingBody: {
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },
    imageContainer: {
        width: '100%',
    },
    // image: {
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     height: '300px',
    //     width:'100%',
    //     margin: '0 auto',
    //     borderRadius: '1rem',
    //     [theme.breakpoints.down('sm')]: {
    //         borderRadius: 0
    //     },
    // },
    image: {
        width: '100%',
        height: 'auto',
        maxHeight: '300px',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    bodyImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '400px',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '25%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            
        },
    },
    avatar: {
        height: '50px',
        width: '50px',
        // margin: theme.spacing(1, 'auto'),
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(2)
            // flexBasis: '250px'
        },
    },
    avatarText: {
        [theme.breakpoints.down('xs')]:{
            textAlign: 'left'
        },
    },
    resultSection: {
        backgroundColor: theme.palette.secondary.light,
        // paddingBottom: theme.spacing(8),
    },
    link: {
        textDecoration: 'underline'
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    buttonContainer: {
        textAlign:'center'
    },
    container: {
    
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          padding: 0,
          // justifyContent: 'center',
        }
    },
}));



const records = [
    {   
        group: 'Alberta',
        location: {name: 'Alberta', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/AB'},
        title: 'Pressure Welder',
        regulationBody: {name: 'Alberta Boilers Safety Association', url:'http://www.absa.ca/' }
    },
    {   
        group: 'Alberta',
        location: {name: 'Alberta', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/AB'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship and Industry Training Office, Government of Alberta', url:'http://tradesecrets.alberta.ca/' }
    },
    {
        group: 'Alberta',
        location: {name: 'Alberta', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/AB'},
        title: 'Welder- Wire Process Operator',
        regulationBody: {name: 'Apprenticeship and Industry Training Office, Government of Alberta', url:'http://tradesecrets.alberta.ca/' }
    },
    {
        group: 'British Columbia',
        location: {name: 'British Columbia', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/BC'},
        title: 'Welder',
        regulationBody: {name: 'Industry Training Authority British Columbia', url:'http://www.itabc.ca/' }
    },
    {
        group: 'British Columbia',
        location: {name: 'British Columbia', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/BC'},
        title: 'Pressure Welder',
        regulationBody: {name: 'Technical Safety British Columbia', url:'https://www.technicalsafetybc.ca/certification/pressure-welder'}
    },
    {
        group: 'Manitoba',
        location: {name: 'Manitoba', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/MB'},
        title: 'Pressure Welder',
        regulationBody: {name: 'Manitoba Office of the Fire Commissioner', url:'http://www.firecomm.gov.mb.ca/itsm_welding.html' }
    },
    {
        group: 'Manitoba',
        location: {name: 'Manitoba', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/MB'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship Manitoba', url:'http://www.gov.mb.ca/wd/apprenticeship/' }
    },
    {
        group: 'New Brunswick',
        location: {name: 'New Brunswick', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NB'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship and Occupational Certification, Government of New Brunswick', url:'http://www2.gnb.ca/content/gnb/en/departments/post-secondary_education_training_and_labour/Skills/content/ApprenticeshipAndTrades.html' }
    },
    {
        group: 'Newfoundland and Labrador',
        location: {name: 'Newfoundland and Labrador', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NL'},
        title: 'Welder and Related Machine Operators',
        regulationBody: {name: 'Apprenticeship and Trades Certification Division, Department of Advanced Education and Skills of Newfoundland and Labrador', url:'http://www.aesl.gov.nl.ca/app/trades.html' }
    },
    {
        group: 'Northwest Territories',
        location: {name: 'Northwest Territories', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NT'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship and Trades, Government of the Northwest Territories', url:'https://www.ece.gov.nt.ca/en/services/apprenticeship-and-trades' }
    },
    {
        group: 'Nova Scotia',
        location: {name: 'Nova Scotia', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NS'},
        title: 'Welder',
        regulationBody: {name: 'Technical Safety Division, Labour and Advanced Education', url:'https://novascotia.ca/lae/publicsafety/' }
    },
    {
        group: 'Nova Scotia',
        location: {name: 'Nova Scotia', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NS'},
        title: 'High Pressure Welder',
        regulationBody: {name: 'Technical Safety Division, Labour and Advanced Education', url:'https://novascotia.ca/lae/publicsafety/' }
    },
    {
        group: 'Nunavut',
        location: {name: 'Nunavut', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/NU'},
        title: 'Welder and Related Machine Operators',
        regulationBody: {name: 'Apprenticeship, Trade and Occupations Certification, Government of Nunavut', url:'http://www.gov.nu.ca/family-services/information/apprenticeship-trade-and-occupations-certification' }
    },
    {
        group: 'Ontario',
        location: {name: 'Ontario', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/ON'},
        title: 'Pressure Systems Welder',
        regulationBody: {name: 'Ontario College of Trades', url:'http://www.collegeoftrades.ca/' }
    },
    {
        group: 'Ontario',
        location: {name: 'Ontario', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/ON'},
        title: 'Welder',
        regulationBody: {name: 'Ontario College of Trades', url:'http://www.collegeoftrades.ca/' }
    },
    {
        group: 'Prince Edward Island',
        location: {name: 'Prince Edward Island', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/PE'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship Training and Skilled Trade Certification, Government of Prince Edward Island', url:'https://www.princeedwardisland.ca/en/topic/apprenticeship-training-and-certification' }
    },
    {
        group: 'Québec',
        location: {name: 'Québec', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/QC'},
        title: 'Welder-assembler',
        regulationBody: {name: 'Emploi Québec', url:'http://www.emploiquebec.gouv.qc.ca/citoyens/developper-et-faire-reconnaitre-vos-competences/qualification-professionnelle/liste-des-metiers/' }
    },
    {
        group: 'Québec',
        location: {name: 'Québec', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/QC'},
        title: 'Welder (construction)',
        regulationBody: {name: 'Commission de la Construction du Québec', url:'http://www.ccq.org/en/H_AccederIndustrie/H02_Metiers' }
    },
    {
        group: 'Québec',
        location: {name: 'Québec', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/QC'},
        title: 'Welder',
        regulationBody: {name: 'Emploi Québec', url:'http://www.emploiquebec.gouv.qc.ca/citoyens/developper-et-faire-reconnaitre-vos-competences/qualification-professionnelle/liste-des-metiers/' }
    },
    {
        group: 'Saskatchewan',
        location: {name: 'Saskatchewan', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/SK'},
        title: 'Pressure Welder',
        regulationBody: {name: 'Technical Safety Authority of Saskatchewan', url:'http://www.tsask.ca/' }
    },
    {
        group: 'Saskatchewan',
        location: {name: 'Saskatchewan', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/SK'},
        title: 'Welder',
        regulationBody: {name: 'Saskatchewan Apprenticeship and Trade Certification Commission', url:'http://www.saskapprenticeship.ca/' }
    },
    {
        group: 'Yukon',
        location: {name: 'Yukon', url: 'https://www.jobbank.gc.ca/marketreport/requirements/23242/YT'},
        title: 'Welder',
        regulationBody: {name: 'Apprenticeship and Tradesperson Qualifications, Yukon Education', url:'http://www.education.gov.yk.ca/trades_apprentice.html' }
    },
];

const headCellsMobile=[
    {id: 'location', label: 'Location'},
];
const useRowStyles = makeStyles((theme) => ({

    innerRow: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
    },
    wrapperInner: {
        '& .MuiGrid-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    link: {
        textDecoration: 'underline'
    }
  }));
function MobileRow(props) {
   const { name, values } = props;
   const [open, setOpen] = useState(false);
   const classes = useRowStyles();
   const { t } = useTranslation('regulation');

   return (
    <>
        <TableRow style={!open ? { borderBottom: 'none' }: {}}>
            <TableCell  colSpan={9} >
                <Typography align="left" variant="body2" color="secondary">
                    {name}
                </Typography>
            </TableCell>
            <TableCell align="right" colSpan={3} >
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon color="secondary"/> : <KeyboardArrowDownIcon color="secondary"/>}
                </IconButton>
            </TableCell>
        </TableRow>
        <TableRow >
            <TableCell style={{ padding:0}} colSpan={12} >
                <Collapse in={open} timeout="auto" unmountOnExit classes={{wrapperInner: classes.wrapperInner}}>
                {
                    values.map((val, idx) => (
                        
                        <Grid container justifyContent="space-between" alignItems="center" className={classes.innerRow}>
                            <Grid item xs={6}>
                                <Box px={2}>
                                <Typography variant="subtitle2" align="left">
                                    {val.title}
                                </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box px={2}>
                                    <Typography variant="subtitle2" align="left">
                                        <Link color="secondary" className={classes.link} href={val.regulationBody.url } target="_blank">
                                            {val.regulationBody.name}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    ))
                }
                </Collapse>
            </TableCell>
        </TableRow>
    </>  
   )

}

const Regulation = () => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { t } = useTranslation('regulation');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const mobileRecords = t('section4.tableRecords', {returnObjects : true}).reduce((acc, d) => {
        if (Object.keys(acc).includes(d.group)) return acc;

        acc[d.group] = t('section4.tableRecords', {returnObjects : true}).filter(g => g.group === d.group); 
        return acc;
    }, {});
    const provinceData = [
        {
            id: 'CA-AB',
            label: t('section5.provinces.AB'),
            code:'CA-AB',
        },
        {
            id: 'CA-BC',
            label: t('section5.provinces.BC'),
            code:'CA-BC',
        },
        {
            id: 'CA-MB',
            label: t('section5.provinces.MB'),
            code:'CA-MB',
        },
        {
            id: 'CA-NB',
            label: t('section5.provinces.NB'),
            code:'CA-NB',
        },
        {
            id: 'CA-NL',
            label: t('section5.provinces.NL'),
            code:'CA-NL',
        },
        {
            id: 'CA-NS',
            label: t('section5.provinces.NS'),
            code:'CA-NS',
        },
        {
            id: 'CA-NT',
            label: t('section5.provinces.NT'),
            code:'CA-NT',
        },
        {
            id: 'CA-NU',
            label: t('section5.provinces.NU'),
            code:'CA-NU',
        },
        {
            id: 'CA-ON',
            label: t('section5.provinces.ON'),
            code:'CA-ON',
        },
        {
            id: 'CA-PE',
            label: t('section5.provinces.PE'),
            code:'CA-PE',
        },
        {
            id: 'CA-QC',
            label: t('section5.provinces.QC'),
            code:'CA-QC',
        },
        {
            id: 'CA-SK',
            label: t('section5.provinces.SK'),
            code:'CA-SK',
        },
        {
            id: 'CA-YT',
            label: t('section5.provinces.YT'),
            code:'CA-YT',
        }
    ];
    const sections = [
        {id:'References', code:'References',  label:t('section6.mapTypes.references')}, 
        {id:'Overview', code:'Overview', label: t('section6.mapTypes.overview')},
        {id:'Certification',code: 'Certification', label:t('section6.mapTypes.certification')}, 
        {id:'Contacts',code: 'Contacts', label:t('section6.mapTypes.contacts')}
    ];
    const headCells = [
        {id: 'location', label: t('section4.tableHeader1')},
        {id: 'title', label: t('section4.tableHeader2')},
        {id: 'body', label: t('section4.tableHeader3')},
    ];
    const { TblContainer, TblHead } = useTable(headCells);
    const [mapType, setMapType] = useState('References');
    const [province, setProvince] = useState('CA-AB');
    const [selectedMapIndex, setSelectedMapIndex] = React.useState(0);
    const [mobileSelectValue, setMobileSelectValue] = React.useState(provinceData[0].code);


    const handleSelectChange = (e) => {
        setMobileSelectValue(e.target.value)
        const province = provinceData.find(item => item.code === e.target.value);
        const index = provinceData.findIndex(item => item.id === province.id);
        if(province) {
            setProvince(province.id);
            setSelectedMapIndex(index);
            scroller.scrollTo('MapTitle', {
                spy:true,
                smooth:true,
                offset: -200,
                duration: 500
            });
        } else {
            setProvince('');
        };
    }

    const handleSelectInfoChange = (e) => {
        setMapType(e.target.value)
        scroller.scrollTo('MapType', {
            spy:true,
            smooth:true,
            offset: -50,
            duration: 500
        });
    }
    let selectedProvinceTitle = province? provinceData.find(item=> item.code === province).label : null;

    useEffect(()=>{
        if (location.state?.scrollToMap) {
            scroller.scrollTo('Province', {
                spy:true,
                smooth:true,
                offset: -50,
                duration: 500
            });
        }
    }, [])
    return (
        <>
        <Helmet>
            <title>
                {t('metaTitle')}
            </title>
            <meta name="description" content={t('metaDesc')} />
        </Helmet>
        <Banner 
            src={regulationHeroMobile} 
            srcset={regulationHeroDeskTop} 
            alt={''}
            iconSrc={iconInternational} 
            iconAlt={t('bannerIconAlt')} 
            title={t('banner')}
            jc={'center'}
        />
        {/** Banner */}
        <Container maxWidth="xl" className={classes.container}>
        <ScrollTopButton />
        <section className={classes.section}>
            <Grid container alignItems="flex-start" spacing={3} style={{margin: 0,width: '100%',}}>
                <Grid item xs={12} md={8}>
                    
                    <Box mb={1}>
                        <div className={`${classes.headerContainer} ${classes.padding}`}>
                            <div className={classes.headerIconContainer}>
                                <img className={classes.headerIcon} src={iconDo} alt={t('section1.headerIconAlt')}/>
                            </div>

                            <div className={classes.headerTextContainer}>
                                <Typography variant="h2" align="left">
                                    {t('section1.header')}
                                </Typography>
                            </div>
                        </div>
                        <Box mt={2}>
                            <div className={`${classes.padding} ${classes.paddingSmall}`}>
                                <Typography variant="body1" color="inherit" align="left" gutterBottom>
                                {t('section1.text')}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                    {/* <Box>
                        <div className={`${classes.headerContainer} ${classes.padding}`}>
                            <div className={classes.headerIconContainer}>
                                <img className={classes.headerIcon} src={iconSearch} alt="Search"/>
                            </div>

                            <div className={classes.headerTextContainer}>
                                <Typography variant="h3" align="left">
                                
                                    What is an educational institution?
                                
                                </Typography>
                            </div>
                        </div>
                        <Box my={2}>
                            <div className={`${classes.padding} ${classes.paddingSmall}`}>
                                <Typography variant="body1" color="inherit" align="left" gutterBottom>
                                Educational institutions are defined as entities that provide
                                instructional services to individuals or education-related
                                services to individuals and other educational institutions.
                                </Typography>
                            </div>
                        </Box>
                    </Box> */}

                </Grid>
                <Grid item xs={12} md={4} >
                    {/* <Paper className={`${classes.image}`} style={{backgroundImage: `url(${bodyImage1})`}}>
                    </Paper> */}
                    <img src={bodyImage1} className={classes.image} alt={t('section1.imageAlt')} />
                </Grid>
            </Grid>
            
            {/* <Grid container>
                <Grid item xs={12} md={8} className={`${classes.headerContainer} ${classes.padding}`}>
                
                    <div className={classes.headerIconContainer}>
                        <img className={classes.headerIcon} src={iconDo} alt=""/>
                    </div>

                    <div className={classes.headerTextContainer}>
                        <Typography variant="h3" align="left">
             
                            What is a regulatory body?
                          
                        </Typography>
                    </div>
         
                </Grid>
            </Grid> */}

           
            {/* <Box my={4} >
                <Grid container justify="center" alignItems="flex-start" spacing={3} className={`${classes.imageSection}`} style={{margin: 0,width: '100%',}}> 
                    
                    <Grid item xs={12} md={8}>
                        <div className={` ${classes.paddingSmall}`}>
                            <Typography variant="body2" color="inherit" align="left" gutterBottom>
                                A regulatory body is a public organization or government
                                agency that is set up to exercise a regulatory function.
                                This involves imposing requirements, conditions or
                                restrictions, settling the standard for activites and 
                                enforcing in these areas or obtaining compliance
                            </Typography>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} md={4} className={classes.imageContainer}>
                        <Paper className={`${classes.image}`} style={{backgroundImage: `url(${bodyImage1})`}}>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </Box>
            
            <Grid container>
                <Grid item xs={12} md={8} className={`${classes.headerContainer} ${classes.padding}`}>
                
                    <div className={classes.headerIconContainer}>
                        <img className={classes.headerIcon} src={iconSearch} alt="Search"/>
                    </div>

                    <div className={classes.headerTextContainer}>
                        <Typography variant="h3" align="left">
                          
                            What is an educational institution?
                           
                        </Typography>
                    </div>
         
                </Grid>
            </Grid>
           
            <Box my={4} >
                <Grid container justify="center" alignItems="flex-start" spacing={3} className={`${classes.imageSection}`} style={{margin: 0,width: '100%',}}> 
                    
                    <Grid item xs={12} md={8}>
                        <div className={` ${classes.paddingSmall}`}>
                            <Typography variant="body2" color="inherit" align="left" gutterBottom>
                                Educational institutions are defined as entities that provide
                                instructional services to individuals or education-related
                                services to individuals and other educational institutions.
                            </Typography>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} md={4} className={classes.imageContainer}>
                        <Paper className={`${classes.image}`} style={{backgroundImage: `url(${bodyImage2})`}}>
                        </Paper>
                        

                    </Grid>
                    
                </Grid>
            </Box> */}
            
        </section>
        </Container>   
        
        {/** End Banner */}

        <Container maxWidth="xl" className={classes.container}>
            <Grid container justify="space-between"  spacing={3} className={`${classes.padding}`} style={{margin: 0,width: '100%',}}>
            <Grid item xs={12}>
                {/** Section 1 */}
                <div className={` ${classes.section}`} id="Requirements">
                    <Box mb={1} py={2}>
                        <Typography variant="h2" align="left" color="secondary" gutterBottom>
                            {t('section2.header')}
                        </Typography>
                    </Box>
                    {/* <Box mb={2} py={1}>
                        <Typography variant="body1" align="left" gutterBottom>
                            This is what you typically need to work as a welder in Canada:
                        </Typography>
                    </Box>
                    <Box mb={1} pb={2}>
                        <Typography variant="h5" align="left" color="secondary" gutterBottom>
                            Welder                    
                        </Typography>
                    </Box> */}
                    <Box mb={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3} >
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section2.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1} >
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                      
                                            <b>{t('section2.graphicText1')}</b>
                                           
                                    </Typography>
                                    </Box>
                                </div>

                            </Grid>
                            
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section2.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1}>
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                     
                                        <b>{t('section2.graphicText2')}</b>
                                       
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section2.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1} >
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                        <b>{t('section2.graphicText3')}</b>
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section2.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1} >
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                        <b>{t('section2.graphicText4')}</b>
                                                                    
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={1} py={2}>
                        <Typography variant="h2" align="left" color="secondary" gutterBottom>
                            {t('section3.header')}
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3} >
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section3.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1}>
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                           <b>{t('section3.graphicText1')}</b>
                                        
                                    </Typography>
                                    </Box>
                                </div>

                            </Grid>
                            
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section3.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1}>
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                           <b>{t('section3.graphicText2')}</b>
                                  
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section3.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1}>
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                        <b>{t('section3.graphicText3')}</b>
                                
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <div className={classes.iconContainer}>
                                    <Avatar className={classes.avatar} alt={t('section3.timelineIcontAlt')} src={iconCertif}></Avatar>
                                    <Box m={1}>
                                    <Typography variant="body2" align="center" className={classes.avatarText}>
                                        
                                        <b>{t('section3.graphicText4')}</b>
                                     
                                    </Typography>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={2} py={2}>
                       
                        <Typography variant="h2" align="left" color="secondary" gutterBottom>
                            {t('section4.header')}
                        </Typography>
                        
                        <Typography variant="body1" align="left" gutterBottom>
                            {t('section4.text')} 
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        {
                            <TblContainer>
                                <TblHead />
                                <TableBody>
                                    {
                                        !isMobile &&
                                        t('section4.tableRecords', {returnObjects : true}).map((item, idx) => 
                                            (<TableRow key={idx}>
                                                <TableCell><Typography align="center" variant="body1"><Link className={classes.link} color="secondary" href={item.location.url} target="_blank">{item.location.name}</Link></Typography></TableCell>
                                                <TableCell><Typography align="center" variant="body1">{item.title}</Typography></TableCell>
                                                <TableCell><Typography align="center" variant="body1"><Link className={classes.link} color="secondary" href={item.regulationBody.url} target="_blank">{item.regulationBody.name}</Link></Typography></TableCell>
                                            </TableRow>))
                                    }
                                    {
                                        isMobile &&
                                        Object.entries(mobileRecords).map(([key, value], i) =>
                                                
                                            <MobileRow key={i} name={key} values={value}/>
                                        )
                                    }
                                </TableBody>
                            </TblContainer>
                        }
                        
                    </Box>
                </div>
                {/** End Section 1 */}
                <Grid container 
                    spacing={3} 
                    justify="flex-start" 
                    alignItems="center" 
                    className={` ${classes.section} `}  
                    style={{margin: 0,width: '100%',}}
                    id={mapType}
                >
                    <Grid item xs={12}>
                        <Box id="Province">  
                            <Typography variant="h3" color="secondary" gutterBottom>
                                {t('section5.header')}
                            </Typography>           
                            <Typography variant="body1" color="secondary" gutterBottom>
                                {t('section5.text')}
                            </Typography>
                        </Box>
                    </Grid>
                    
                    {
                        !isMobile && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <MapList 
                                        selectedIndex={selectedMapIndex}
                                        setSelectedIndex={setSelectedMapIndex}
                                        setProvince={setProvince}
                                        setMobileSelectValue={setMobileSelectValue}
                                        provinces={provinceData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Map 
                                        selectedIndex={selectedMapIndex}
                                        setSelectedIndex={setSelectedMapIndex}
                                        setProvince={setProvince}
                                        mapType={mapType}
                                        province={province}
                                        provinces={provinceData}
                                        setMobileSelectValue={setMobileSelectValue}
                                    />
                                </Grid>
                            </>
                        )
                    }
                    {
                        isMobile && (
                            <Grid item xs={12}>
                            <Select 
                               
                                label={t('section5.selectProvinceLabel')}
                                name="selectedProvince"
                                value={mobileSelectValue}
                                onChange= {handleSelectChange}
                                options={provinceData}
                                hideNoneOption={true}
                            />
                            </Grid>
                        )
                    }

                </Grid>
            </Grid>
        </Grid>
        </Container>
       {/** Section 3 */}

       {
           province && 
            <Grid container 
                spacing={3} 
                justify="center" 
                alignItems="center" 
                className={` ${classes.section} ${classes.resultSection}`} 
                style={{margin: 0,width: '100%',}}
            >
                <Grid item xs={12}>
                    <Box mt={3}>
                        <Element name="MapTitle">
                        <Typography variant="h3" color="secondary" gutterBottom align="center">
                            <b>{selectedProvinceTitle}</b>
                        </Typography>
                        </Element>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Select 
                                
                        label={t('section6.selectInformationLabel')}
                        name="selectedInformation"
                        value={mapType}
                        onChange= {handleSelectInfoChange}
                        options={sections}
                        hideNoneOption={true}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Box mt={3}>
                        <Element name="MapType">
                        <Typography variant="h3" color="textPrimary" gutterBottom align="center">
                            <b>{sections.find(item => item.code === mapType).label}</b>
                        </Typography>
                        </Element>
                    </Box>
                </Grid>
                
                <Grid item xs={12}>
                
                <section id="MapResult">
                    <MapResult 
                        mapType={mapType}
                        province={province}
                    />
                </section>
                
                </Grid>

                <Grid item xs={12}>
                    <Box className={classes.buttonContainer} my={3}>
                    <Button
                    endIcon={<ArrowUpwardIcon />} 
                    color="primary" 
                    size="large"
                    variant="contained" 
                    disableElevation
                   
                    className={classes.buttonRoot}
                     component={ScrollLink} 
                    to="Province"
                    
                >
                    <Typography variant="h5">
                    {t('section6.backToProvincesBtn')}
                    </Typography>
                </Button>
                    </Box>
                </Grid>
            </Grid>
       }
        
        {/** End Section 3 */}                         

        </>
    );
};

export default Regulation;