import React, { useEffect,} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Container, CircularProgress } from "@material-ui/core";
import {useAuth} from '../components/useAuth';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    iframeContainer: {
        height: '100%'
    }

}));
const TestAssessment = (props) => {
    const classes = useStyles();
    const { t,  i18n} = useTranslation(['common', 'error']);
    const iframeRef = React.createRef();
    const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const {token, user} = useAuth();
    let history = useHistory();
    let quizLink='';
    
    if(user) {
        if (i18n.language.substr(0, 2) === 'en') {
            quizLink = ``;
        } else if (i18n.language.substr(0, 2) === 'fr') {
            quizLink = ``;
        } else if (i18n.language.substr(0, 2) === 'uk') {
            quizLink = ``;
        }
    }

    useEffect(() => {
        const iframeElement = iframeRef && iframeRef.current;
        if(iframeElement) {
            if(user) {
                if (i18n.language.substr(0, 2) === 'en') {
                    iframeElement.src = ``;
                } else if (i18n.language.substr(0, 2) === 'fr') {
                    iframeElement.src = ``;
                } else if (i18n.language.substr(0, 2) === 'uk') {
                    iframeElement.src = ``;
                }
            }
            setTimeout(() => {
                if(iframeElement.contentWindow) {
                    iframeElement.contentWindow.postMessage({message: 'language', language:i18n.language}, '*');
                }
            }, 600)
            
        }

    }, [i18n.language])

    useEffect(() => {
        const iframeElement = iframeRef && iframeRef.current;
        if(iframeElement && iframeElement.contentWindow) {
            iframeElement.contentWindow.postMessage({message: 'language', language:i18n.language}, '*');
        }
    }, [iframeRef])

    const handleIframeLoad = () => {
        setLoading(false);
    }
  
    return (
        <>
        {
            token && user ? (
            
               
            <Container maxWidth="xl" className={classes.container}>
                    <>
                        <Box my={3}>
                            <Typography variant="h3" align="center" gutterBottom>
                               {t('testAssessment.title')}
                            </Typography>
                        </Box>
                        {

                            loading && 
                            <Box display="flex" justifyContent="center">
                                <CircularProgress color="secondary" />
                            </Box>
                        }
                        

                        <Box  my={3} className={classes.iframeContainer}>
                                <iframe
                                    ref={iframeRef}
                                    src={quizLink}
                                    title={t('testAssessment.title')}
                                    height={500}
                                    width="100%"
                                    frameBorder="0"
                                    onLoad={handleIframeLoad}
                                >
                                </iframe>
                        </Box>
                    </>   
            </Container>
            ) : (
            <Container maxWidth="lg">
                <Box my={3}>
                    <Typography variant="h3" align="center">
                        {t('error:loginRequired')}
                    </Typography>
                </Box>
            </Container>
            )
        }
        
        </>
    )
}

export default TestAssessment
