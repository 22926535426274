import React from 'react'
import { 
    makeStyles,
    Grid,
    Typography,
    Box
} from '@material-ui/core';
import {getProvinceFromId} from '../data';
import WelderBox from '../../../components/WelderBox'
import RedSeal from '../../../components/RedSeal';
import ListBox from '../../../components/ListBox';
import ArrowBox from '../../../components/ArrowBox';
import {useTranslation} from "react-i18next";

const section2WelderData = [
    {
        title:"Welder Level 1", 
        subtitle:"Technical Training - 240 Hours (8 weeks)", 
        secondaryTitle:"Work-Based/on-the-job Training:",
        secondarySubtitle:"", 
        hours:"1500 Hours"
    },
    {
        title:"Welder Level 2", 
        subtitle:"Technical Training - 240 Hours (8 weeks)", 
        secondaryTitle:"Work-Based/on-the-job Training:",
        secondarySubtitle:"", 
        hours:"1500 Hours"
    },
    {
        title:"Welder Level 3", 
        subtitle:"Technical Training - 240 Hours (8 weeks)", 
        secondaryTitle:"Work-Based/on-the-job Training:",
        secondarySubtitle:"", 
        hours:"1500 Hours"
    },
];
// const listData = [
//     " 3G- Vertical Butt Weld (GMAW) (FCAW)",
// " 45° Overhead Butt Weld (SMAW)",
// " 4G- Overhead Butt Weld (SMAW)",
// " 2G- Horizontal BUTT (GTAW) & (SMAW)",
// " Cutting (O.A.C.)",
// ]

const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        backgroundColor: theme.palette.common.white,
        padding:theme.spacing(1),
    },
    boxWrapper: {
        display:'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column'
        }
    }
}));


const AB = () => {
    const {t} = useTranslation('regulation');
    
    const classes = useStyles();

    const section1WelderData = [
        {
            title:t('pathways.lvl1'), 
            subtitle:`${t('pathways.techTraining')} 240 ${t('pathways.hours')} (8 ${t('pathways.weeks')}})`, 
            secondaryTitle: t('pathways.workTraining'),
            secondarySubtitle:"", 
            hours:`1500 ${t('pathways.hours')}`
        },
        {
            title:t('pathways.lvl2'),
            subtitle:"", 
            secondaryTitle: t('pathways.workTraining'),
            secondarySubtitle:"", 
            hours:`1800 ${t('pathways.hours')}`
        },
    ];
    const listData = t('pathways.processList1', {returnObjects: true});
    return (
        <>  
            <div className={classes.container}>
                {/**Section 1 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="body1" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.AB.section1.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        <ArrowBox>
                            <WelderBox maxWidth="300px">
                                <Box py={1}>
                                    <Typography variant="h4" align="center">
                                        <b>{t('pathways.pOfEdPreq')}</b>   
                                    </Typography>
                                    
                                </Box>
                                <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                    <Typography variant="body1" align="left" guttterBottom>
                                    {t('pathways.succCompletion')}
                                        <br/>
                                        {t('pathways.engAndMath')}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom>
                                        <b>{t('pathways.or')}</b>
                                     
                                    </Typography>
                                    <Typography variant="body1" align="left" guttterBottom>
                                    {t('pathways.passGED')}   
                                    </Typography>     
                                </Box>
                            </WelderBox>
                        </ArrowBox>


                        {
                            section1WelderData.map((data, idx) => {
                                
                                return (
                                    <ArrowBox >
                                    <WelderBox 
                                    key={idx}
                                    title={data.title}
                                    subtitle={data.subtitle}
                                    secondaryTitle={data.secondaryTitle}
                                    secondarySubtitle={data.secondarySubtitle}
                                    hours={data.hours}

                                    />
                                    </ArrowBox>
                                )
                            }
                                
                            )
                        }
                        <Grid item md={3}>
                            <WelderBox 
                                jc={"center"}
                                title={t('pathways.journeyCertWireProc')}
                            />
                        </Grid>
                    </Grid>
                </Box>  



                {/**Section 2 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.AB.section2.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        <ArrowBox mdCols="2" >
                            <WelderBox maxWidth="300px">
                                <Box py={1}>
                                    <Typography variant="h4" align="center">
                                        <b>{t('pathways.entranceExam')}</b>   
                                    </Typography>
                                    <Typography variant="h4" align="center" gutterBottom>
                                        <b>{t('pathways.or')}</b>
                                    </Typography>
                                    <Typography variant="h4" align="center" guttterBottom>
                                       <b>{t('pathways.pOfEdPreq')}</b>
                                    </Typography>  
                                </Box>
                                <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                    <Typography variant="body1" align="left" guttterBottom>
                                    {t('pathways.succCompletion')} 
                                        <br/>
                                        {t('pathways.engAndMath')}
                                    </Typography>
                                    <Typography variant="body1" align="center" gutterBottom>
                                        <b>{t('pathways.or')}</b>
                                    </Typography>
                                    <Typography variant="body1" align="left" guttterBottom>
                                        {t('pathways.passGED')}
                                    </Typography>
                                </Box>
                            </WelderBox>
                        </ArrowBox>
                        
                        <ArrowBox mdCols="2">
                            <WelderBox 
                                maxWidth="300px"
                                title={t('pathways.welderLvl1') }
                                subtitle={`${t('pathways.techTraining')} 240 ${t('pathways.hours')} (8 ${t('pathways.weeks')}})` }
                                secondaryTitle={t('pathways.workTraining')}
                                secondarySubtitle="" 
                                hours={`1500 ${t('pathways.hours')}`}
                            />
                        </ArrowBox>
                            
                        <ArrowBox mdCols="2">
                            <WelderBox 
                                maxWidth="300px"
                                title={t('pathways.welderLvl2') }
                                subtitle={`${t('pathways.techTraining')} 240 ${t('pathways.hours')} (8 ${t('pathways.weeks')}})`}
                                secondaryTitle={t('pathways.workTraining')}
                                secondarySubtitle="" 
                                hours={`1500 ${t('pathways.hours')}`}
                            />
                        </ArrowBox>

                        <ArrowBox mdCols="2">
                            <WelderBox
                            maxWidth="300px" 
                            title={t('pathways.welderLvl3') }
                            subtitle={`${t('pathways.techTraining')} 240 ${t('pathways.hours')} (8 ${t('pathways.weeks')}})`}
                            secondaryTitle={t('pathways.workTraining')}
                            secondarySubtitle="" 
                            hours={`1500 ${t('pathways.hours')}`}
                            />
                        </ArrowBox>

                        <RedSeal mdCols="2"/>
                    </Grid>
                </Box> 

                {/**Section 3 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.AB.section3.title')}</b>
                        </Typography>
                        <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.challExam')}
                            <br />
                                {t('pathways.expWorkersText')}
                        </Typography>

                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        <ArrowBox mdCols={4}>
                        <WelderBox 
                            subtitle={t('pathways.AB.section3.Box1.title')}
                            secondarySubtitle={t('pathways.AB.section3.Box1.body')}
                            maxWidth={"300px"}
                            
                        />
                        </ArrowBox>
                        <ArrowBox mdCols={4}>

                            <ListBox 
                                title={t('pathways.pOfCompProcess')}
                                list={listData}
                                maxWidth={"300px"}
                            />

                            <Box my={6}>
                                <Typography variant="h5" color="secondary">
                                    <b>{t('pathways.or')}</b>
                                </Typography>
                            </Box>

                            <WelderBox 
                                subtitle={t('pathways.ticketsHeader')}
                                secondarySubtitle={t('pathways.ticketsBody')}
                                maxWidth={"300px"}
                            />


                        </ArrowBox>
                        


                        <RedSeal mdCols={4}/>
                    </Grid>
                </Box>
            </div>
           
        </>
    )
}

export default AB
