import React from 'react'
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import trainingImage1 from '../../assets/training/training-image1.png';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    sectionImage: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'100%',
        margin: '0 auto'
    },
    link: {
        textDecoration: 'none',
    },
    paddingSmall: {
        [theme.breakpoints.down('sm')]:{
          paddingRight: '12px',
          paddingLeft: '12px',
        }
      },
      
      imageSection: {
        [theme.breakpoints.down('xs')]:{
          flexDirection: 'column-reverse',
          alignItems:'center',
        }
      },
      
      imageContainer: {
        width: '100%',
      },
      // image: {
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundPosition: 'center top',
      //   height: '300px',
      //   width:'100%',
      //    margin: '0 auto',
      //   borderRadius: '1rem',
      //   [theme.breakpoints.down('sm')]: {
      //    borderRadius: 0
      //   },
      // },
      bodyImage: {
        width: '100%',
        height: 'auto',
        maxHeight: '400px',
        
      },
}));


const TrainingSection = () => {
    const classes = useStyles();
    const { t } = useTranslation('weldingTraining');

    return (
        <>
        <div className={` ${classes.paddingSmall}`}> 
            <Box mb={3}>
            <Typography variant="h2" color="inherit" gutterBottom >
              {t('section1.header')}
            </Typography>
            </Box>
            <Grid container spacing={3} justify="center" alignItems="flex-start" className={` ${classes.imageSection}`} >
                <Grid item xs={12} md={8}>
                    
                  <Typography variant="body1" color="inherit" align="left" paragraph>
                    {t('section1.text1')}
                  </Typography>
                  <Typography variant="body1" align="left" paragraph>
                    {t('section1.text2')}
                  </Typography>
                    
                </Grid>

                <Grid item xs={12} md={4}>
                  <img className={classes.bodyImage} alt={t('section1.imageAlt')} src={trainingImage1} />
                </Grid>
            </Grid>
            </div>
        </>
    )
}

export default TrainingSection;
