import React from 'react'
import { 
    makeStyles,
    Grid,
    Typography,
    Box,
    useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        listStyle: 'none',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        padding: theme.spacing(1,2)
    }, 
}));



const OverviewListItem = ({title, body, hours}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <>
        <Box px={3} py={2}  className={classes.listItem} component="li">
            <Typography variant="h4">
                <b>{title}</b>
            </Typography>
        </Box>
        <Box px={3} py={2}  className={classes.listItem} component="li">
            <Grid container spacing={2} justify="space-between" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                        {body}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1" align={isMobile? 'left': 'right'}>    
                        {hours}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        </>
    );
}

export default OverviewListItem