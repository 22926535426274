import request from './base';
import {excludeObjNullValue} from '../utils/common';

export const fetchPastResultsPresent = () => request.getJSON(
    `/past_result`
);

export const fetchQuizResults = (quizId) => request.getJSON(
    `/assessment_output/${quizId}`
);

export const fetchUserQuizId = (email) => request.postJSON(
    '/assessment_email',
    excludeObjNullValue({
        email: email,
    })
);