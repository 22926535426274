import React from "react";
import { Grid, Typography, Container, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    container: {
        // height: '100vh',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3) 
    },
    section: {
        marginTop: theme.spacing(3) 
    }
}))
const About = () => {
    const classes = useStyles();
    const { t } = useTranslation('about');
    return (
        <>
            <Helmet>
                <title>
                    {t('metaTitle')}
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Container maxWidth="xl" className={classes.container}>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h1" align="left" gutterBottom>
                            {t('title')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('text3')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('text4')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section1.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section1.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="about:section1.text2">
                            Learn more about <a href="https://www.cwbgroup.org/services/certification-and-qualification"> certification</a>
                            </Trans>
                            
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="about:section1.text3">
                                Learn more about <a href="https://www.cwbgroup.org/about/history"> history of the Welding Bureau</a>
                            </Trans>
                        </Typography>
                        
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section2.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section2.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('section2.text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('section2.text3')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="about:section2.text4">
                                Learn more about the full range of <a href="https://www.cwbgroup.org/education">  CWB Education courses being offered</a>
                            </Trans>                    
                        </Typography>
                        
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section3.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section3.text1')}
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                        {t('section4.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                        {t('section4.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {t('section4.text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans i18nKey="about:section4.text3">
                                Learn more about the <a href="https://www.cwbgroup.org/association">  CWB Association</a>
                            </Trans>  
                        </Typography>
                    </Box>
                </section>
                <section className={classes.section}>
                    <Box mb={2}>
                        <Typography variant="h2" align="left" gutterBottom>
                            {t('section5.header')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            {t('section5.text1')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('section5.text2')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('section5.text3')}
                        </Typography>
                    </Box>
                </section>
            </Container>
        </>
    );
};

export default About;