import React from 'react'
import { 
    Typography,
    Box
} from '@material-ui/core';
const ListBox = ({title, list, maxWidth="none"}) => {
    return (
        <>
            <Box  p={2} maxWidth={maxWidth}  borderRadius="1rem" bgcolor="secondary.light" overflow="hidden">
                <Typography variant="body1" gutterBottom>
                    {title}
                </Typography>
                <ol>
                    {list.map((item, idx) => 
                        <li key={idx}>
                            <Typography variant="body1">
                                {item}
                            </Typography>
                        </li>
                    )}
                </ol>
            </Box>
        </>
    )
}

export default ListBox
