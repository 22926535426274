import React from 'react'
import { 
    makeStyles,
    Grid,
    Typography,
    Box
} from '@material-ui/core';
import WelderBox from '../../../components/WelderBox'
import RedSeal from '../../../components/RedSeal';
import ListBox from '../../../components/ListBox';
import ArrowBox from '../../../components/ArrowBox';
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        backgroundColor: theme.palette.common.white,
        padding:theme.spacing(1),
    },
    boxWrapper: {
        display:'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column'
        }
    }
}));


const ON = () => {
    const classes = useStyles();
    const {t} = useTranslation('regulation');
    const welderData = [
        {
            title:t('pathways.welderLvl1'), 
            subtitle:`${t('pathways.techTraining')} 300 ${t('pathways.hours')}`, 
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5280 ${t('pathways.hours')}`
        },
        {
            title:t('pathways.welderLvl2'), 
            subtitle:`${t('pathways.techTraining')} 180 ${t('pathways.hours')}`, 
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5280 ${t('pathways.hours')}`
        },
        {
            title:t('pathways.welderLvl3'), 
            subtitle:`${t('pathways.techTraining')} 240 ${t('pathways.hours')}`, 
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5280 ${t('pathways.hours')}`
        }
    ];
    const listData = t('pathways.processList1', {returnObjects: true});

    return (
        <>  
            <div className={classes.container}>
                {/**Section 1 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.ON.section1.title')}</b>
                        </Typography>
                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        {
                            welderData.map((data, idx) => {
                                // const {title, subtitle, secondaryTitle, secondarySubtitle, hours} = data;
                                return (
                                    <ArrowBox>
                                    <WelderBox 
                                    key={idx}
                                    title={data.title}
                                    subtitle={data.subtitle}
                                    secondaryTitle={data.secondaryTitle}
                                    secondarySubtitle={data.secondarySubtitle}
                                    hours={data.hours}

                                    />
                                    </ArrowBox>
                                )
                            }
                                
                            )
                        }
                        <RedSeal />
                    </Grid>
                </Box>  

                {/**Section 2 */}
                <Box my={8}>
                    <Box mb={3} p={1}>
                        <Typography variant="h5" align="center" gutterBottom color="secondary">
                            <b>{t('pathways.ON.section2.title')}</b>
                        </Typography>
                        <Typography variant="body1" align="left" gutterBottom>
                            {t('pathways.challExam')}
                            <br />
                            {t('pathways.expWorkersText')}
                        </Typography>

                    </Box>
                    <Grid container justify="center" alignItems="center" spacing={1}> 
                        
                        <ArrowBox mdCols={3}>
                        <WelderBox 
                            subtitle={t('pathways.ON.section2.Box1')}
                            maxWidth={"300px"}
                            
                        />
                        </ArrowBox>
                        <ArrowBox mdCols={3}>
                        <WelderBox 
                             subtitle={t('pathways.ON.section2.Box2.title')}
                             secondarySubtitle={t('pathways.ON.section2.Box2.body')}
                            maxWidth={"300px"}
                            
                        />
                        </ArrowBox>
                        <ArrowBox mdCols={3}>

                            <ListBox 
                                title={t('pathways.pOfCompProcess')}
                                list={listData}
                                maxWidth={"300px"}
                            />

                            <Box my={6}>
                                <Typography variant="body1" color="secondary">
                                    <b>{t('pathways.or')}</b>
                                </Typography>
                            </Box>

                            <WelderBox 
                                subtitle={t('pathways.ticketsHeader')}
                                secondarySubtitle={t('pathways.ticketsBody')}
                                maxWidth={"300px"}
                            />


                        </ArrowBox>
                        


                        <RedSeal mdCols={3}/>
                    </Grid>
                </Box>
            </div>
           
        </>
    )
}

export default ON
