import React from 'react';
import { 
    List,
    ListItemText,
    // ListItem,
    makeStyles,
    withStyles
} from "@material-ui/core";
import { Link } from 'react-scroll';
import MuiListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
    container: {
         maxWidth: 150,
    },
    list: {
        position:'relative',
        "&:after": {
            content: "''",
            position: 'absolute',
            zIndex:'-1',
            top: '10%',
            bottom:'10%',
            left: '50%',
            width:'3px',
            backgroundColor: theme.palette.secondary.main,
        },
        '& a.active .MuiListItem-root ': {
            color: '#FFF',
            backgroundColor: theme.palette.secondary.main
        },
        '& a:not(.active) .MuiListItem-root ': {
            color: theme.palette.secondary.main,
            backgroundColor: '#FFF',
        },
    },
    item: {
        '& span, & svg': {
            fontSize: '1rem',
        }
    },

}));

const ListItem = withStyles((theme)=>({
    root: {
        textAlign: 'center',
        border: `3px solid ${theme.palette.secondary.main}`,
        borderRadius: '0.8rem',
        fontWeight: '700',
        color: theme.palette.secondary.main,
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(2),
        "&$selected": {
            color: '#FFF',
            backgroundColor: theme.palette.secondary.main
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.secondary.light,
            color: "white"
        }, 
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: "white"
        }
    },
    selected: {
        color: '#FFF',
        backgroundColor: theme.palette.secondary.main,
    }

}))(MuiListItem);

const ScrollMenu = ({sections, setMapType, offset=-50}) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
     

    // const handleSetActive = (id) => {
     
    //     let idx = sections.indexOf(id);
    //     // setSelectedIndex(idx);
    //     if(setMapType) {
    //         // setMapType(id)
    //     }
        
    // }

    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
      if(setMapType) {
        setMapType(sections[index].id);
      }  
    };
  
    return (
      <div className={classes.container}>   
        <List component="nav" className={classes.list}>
            {
                sections.map((section, idx) => {
                    return ( 
                        
                        <Link
                            key={idx}
                            activeClass={"active"}
                            to={section.id}
                            spy={true}
                            smooth={true}
                            offset={offset}
                            duration={500}
                            // onSetActive={handleSetActive}
                            
                        >
                            <ListItem 
                                button
                                selected={selectedIndex === idx}
                                onClick={(event) => handleListItemClick(event, idx)}
                                // classes={{root: classes.root, selected: classes.selected}}
                            >    
                                <ListItemText primary={section.title} className={classes.item}/>
                            </ListItem>
                            
                        </Link>
                        
                    )
                })
            }
        </List>
      </div>
    )
};

export default ScrollMenu;
  