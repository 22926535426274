import {isPlainObject} from 'lodash/lang';



const objToQuery = obj => Object.entries(obj)
    .filter((entry) => typeof entry[1] !== 'undefined')
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&');


const normalizeUrl = url => url.slice(-1) === '?' ? url.slice(0, url.length - 1) : url;


export const buildUrl = (url, query) => normalizeUrl(`${process.env.REACT_APP_SERVER_BASE_URL}${url}?${objToQuery(query)}`);

export const toCamelCase = v => v.replace(/\B_[a-z0-9]/g, m => m[1].toUpperCase());

export const snakeToCamel = data => {
    if (Array.isArray(data)) {
        const ret = [];
        for (let item of data) {
            ret.push(snakeToCamel(item));
        }
        return ret;
    } else if (isPlainObject(data)) {
        let ret = {};
        for (let [k, v] of Object.entries(data)) {
            ret[toCamelCase(k)] = snakeToCamel(v);
        }
        return ret;
    }
    return data;
};

export const toSnakeCase = v => v.replace(/\B[a-z0-9][A-Z]/g, m => m[0] + '_' + m[1].toLowerCase());

export const camelToSnake = data => {
    if (Array.isArray(data)) {
        const ret = [];
        for (let item of data) {
            ret.push(camelToSnake(item));
        }
        return ret;
    } else if (isPlainObject(data)) {
        let ret = {};
        for (let [k, v] of Object.entries(data)) {
            ret[toSnakeCase(k)] = camelToSnake(v);
        }
        return ret;
    }
    return data;
};

export const excludeObjNullValue = data => {
    const ret = {};
    for (let [k, v] of Object.entries(data)) {
        if (v !== null && v !== undefined) {
            ret[k] = v;
        }
    }
    return ret;
};



