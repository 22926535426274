import React, {useLayoutEffect, useEffect} from 'react';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CssBaseline from '@material-ui/core/CssBaseline';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import About from "./pages/About";
import Assessment from "./pages/Assessment";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import FindJob from "./pages/FindJob";
import HireWelder from "./pages/HireWelder";
import InternationalWelders from "./pages/InternationalWelders";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import ProgramInfo from "./pages/ProgramInfo";
import ProvinceToProvince from "./pages/ProvinceToProvince";
import Regulation from "./pages/Regulation";
import TermsAndConditions from "./pages/TermsAndConditions";
import WeldingTraining from "./pages/WeldingTraining";
import Result from "./pages/Result";
import StartAssessment from "./pages/StartAssessment";
import SuccessStories from "./pages/SuccessStories"
import TestAssessment from "./pages/TestAssessment";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import CookieConsent from './components/CookieConsent';
import Popup from './components/Popup';
import SignupConfirm from './pages/Signup/SignupConfirm'; 
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import NewPassword from "./pages/NewPassword";
import BottomBar from "./components/BottomBar";
import {useAuth} from './components/useAuth';
import i18n from './i18n';
// const tagManagerArgs = {gtmId:'GTM-5V8W2CH'};
// TagManager.initialize(tagManagerArgs);
const trackingId = process.env.REACT_APP_GA_TRACKING_CODE;
ReactGA.initialize(trackingId);

function Routes(props) {
  const history = useHistory();
  let location = useLocation();
  const {setOpenSignup, openSignup, setOpenSignupConfirm,openSignupConfirm } = props;

  React.useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    ReactGA.set({page: window.location.pathname});
    ReactGA.pageview(window.location.pathname);
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  let background = location.state && location.state.background;

  return (
    <div>
      <Switch location={background || location}>
        <Route exact path="/welder-competency-credential-assessment"><Home/></Route>
        <Route exact path="/about-cwb-welders-canada" component= {About} />
        <Route exact path="/welder-competency-credential-assessment-information"><Assessment/> </Route>
        <Route exact path="/contact-us" component= {Contact} />
        <Route exact path="/welders-work-canada-faq" component={Faq} />
        <Route exact path="/search-welding-jobs-canada" component= {FindJob} />
        <Route exact path="/hireWelder" component={HireWelder} />
        <Route exact path="/international-welder-requirements-work-canada" component= {InternationalWelders} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/welder-competency-credential-assessment-program-information" component= {ProgramInfo} />
        <Route exact path="/welder-requirements-across-canada" component={ProvinceToProvince} />
        <Route exact path="/welders-certifications-requirements-canada" component= {Regulation} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        <Route exact path="/welding-training-canada" component= {WeldingTraining} />
        <Route exact path="/result" component= {Result} />
        {/* <PrivateRoute><StartAssessment /></PrivateRoute> */}
        <Route exact path="/start-assessment" component={StartAssessment} />
        <Route exact path="/test-assessment" component={TestAssessment} />
        <Route exact path="/success-stories" component={SuccessStories} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/login/reset-password" component={NewPassword} />
        {/* <Route exact path="/change-password" component={ChangePassword} /> */}
        <Redirect to="/welder-competency-credential-assessment"/>
      </Switch>
      {background && <Route exact path="/signup" children={<SignupModal setOpenSignup={setOpenSignup} openSignup={openSignup} setOpenSignupConfirm={setOpenSignupConfirm}/>}/>}
      {background && <Route exact path="/signup-confirm" children={<SignupConfirmModal openSignupConfirm={openSignupConfirm} setOpenSignupConfirm={setOpenSignupConfirm}/>}/>}

    </div>
  )
}




const SignupModal = (props) => {
    const {openSignup, setOpenSignup, setOpenSignupConfirm} = props;
    return (
      <Popup
          // openPopup={openSignup}
          openPopup={true}
          setOpenPopup={setOpenSignup}
      >
        <Signup setOpenPopup={setOpenSignup} setOpenSignupConfirm={setOpenSignupConfirm}/>
      </Popup>
    )
}

const SignupConfirmModal = (props) => {
  const { setOpenSignupConfirm, openSignupConfirm} = props;
  return (
      <Popup
        openPopup={true}
        setOpenPopup={setOpenSignupConfirm}
      >
        <SignupConfirm setOpenPopup={setOpenSignupConfirm}/>
      </Popup>
  )
}

function App() {
 
  const [cookieConsetOpen, setCookieConsentOpen] = React.useState(true);
  const [openSignup, setOpenSignup] = React.useState(false);
  const [openSignupConfirm, setOpenSignupConfirm] = React.useState(false);
  
  return (
    <>
      
      <Navbar setOpenSignup={setOpenSignup} i18n={i18n}/>
      <Routes setOpenSignup={setOpenSignup} openSignup={openSignup} setOpenSignupConfirm={setOpenSignupConfirm} openSignupConfirm={openSignupConfirm} />
      
      <Footer />
      <BottomBar />
      
      <Popup
        openPopup={cookieConsetOpen}
        setOpenPopup={setCookieConsentOpen}
      >
         <CookieConsent setOpen={setCookieConsentOpen}/>
      </Popup>
      
      {/* <Popup
        openPopup={openSignup}
        setOpenPopup={setOpenSignup}
      >
        <Signup setOpenPopup={setOpenSignup} setOpenSignupConfirm={setOpenSignupConfirm}/>
      </Popup> */}

      {/* <Popup
        openPopup={openSignupConfirm}
        setOpenPopup={setOpenSignupConfirm}
      >
        <SignupConfirm setOpenPopup={setOpenSignupConfirm}/>
      </Popup> */}
      
    </>
  );
}
// function PrivateRoute ({children, ...rest}) {
//   const {token, setToken} = useAuth();
//   let jwtToken = localStorage.getItem('cwbJwt');
//   let userToken = token;
//   if (jwtToken && !token) {
//     userToken=jwtToken;
//   }
//   return (
//     <Route {...rest} render={(props) => {      
//       if(userToken){
//         return children
//       } else {
//         return <Redirect to={{
//           pathname: "/signup",
//           state: { background: {hash: "", pathname: "/", search: "", state: undefined} }
//         }} />
//       }
//     }} />
//   )
// }



export default App;
