import React from 'react'
import { 
   Typography,Box,Button,
} from '@material-ui/core';
import {renderText} from '../../components/renderText';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useTranslation} from "react-i18next";

const OverViewParagraph = ({data}) => {
    const {title, body, items, downloadLink=''} = data;
    const { t } = useTranslation('regulation');
    return (
        <Box my={3}>
            <Box mb={3}>
                <Typography variant="body1" align="center">
                    <b>{title}</b>
                </Typography>
            </Box>
            <Box mb={3}>
                {
                    body.map((item, idx) =>
                    <Typography variant="h5" align="left" key={idx}>
                        <b>
                            {renderText(item)}
                        </b>
                    </Typography>
                    )
                }
                
            </Box>
            <Box>
                <ul>
                {
                    items.map((item, idx) =>
                        <li>
                        <Typography variant="body1" gutterBottom key={idx}>{renderText(item)}</Typography>
                        </li>
                    )
                }
                </ul>
            </Box>
            {
                downloadLink &&
                <Box my={3}>
                    <Button
                        startIcon={<GetAppIcon />} 
                        color="primary" 
                        size="large"
                        variant="contained" 
                        disableElevation
                        href={downloadLink}
                        target="_blank"
                    >
                        <Typography variant="h5">
                        {t('section6.downloadText')}
                        </Typography>
                    </Button>
                </Box>
            }
            


        </Box>
    )
}

export default OverViewParagraph
