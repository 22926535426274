import React from 'react';
import { Link } from '@material-ui/core';

export const renderText = txt => {
    const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;  
    const MAILTO_REGEX = /\S+@\S+\.\S+/;
    return txt
      .split(" ")
      .map(part =>
        URL_REGEX.test(part) ?
         <Link style={{textDecoration: 'underline'}} color="secondary" href={part} target="_blank">{part} </Link> 
         : MAILTO_REGEX.test(part) ?
         <Link style={{textDecoration: 'underline'}} color="secondary" href={`mailto:${part}`}>{part}</Link>
         : part + " "
      )
}

export const renderMailTo = txt => {
  const re = /\S+@\S+\.\S+/;
  return txt
    .split(" ")
    .map(part => 
        re.test(part) ? <Link style={{textDecoration: 'underline'}} color="secondary" href={`mailto:${part}`}>{part}</Link>: part + " "
    );
}