import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ProvideAuth } from "./components/useAuth.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router} from "react-router-dom";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import NimbusSansDOTLightTtf from './fonts/Nimbus-Sans-D-OT-Light_32752.ttf';
import MyriadProRegular from './fonts/MYRIADPRO-REGULAR.woff';
import MyriadProSemibold from './fonts/MYRIADPRO-SEMIBOLD.woff';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      autoSessionTracking: true,
      integrations: [
          new Integrations.BrowserTracing(),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
  });
}

const myriadProRegular = {
  fontFamily: 'Myriad Pro Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: `local('Myriad Pro Regular'), url(${MyriadProRegular}) format('woff')`,
}
const myriadProSemibold = {
  fontFamily: 'Myriad Pro Semibold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: `local('Myriad Pro Regular'), url(${MyriadProSemibold}) format('woff')`,
}
const nimbus = {
  fontFamily: 'Nimbus Sans D Ot',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Nimbus Sans D Ot'),
    url(${NimbusSansDOTLightTtf}) format('truetype')
  `
}

const defaultTheme = createMuiTheme({});
const {breakpoints} = defaultTheme;

let theme = createMuiTheme({
  breakpoints: {
    values: {
      // mobile: 0,
      // tablet: 640,
      // laptop:1024,
      // desktop:1280
      xs: 0,
      sm: 480,
      md: 640,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      dark: '#b29700',
      light: '#ffdf33',
      main: '#FFD800',
    },
    secondary: {
      dark: '#085f83',
      light: '#E8EDEF',
      main: '#0C89BC',
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    },
    background: {
      paper: '#FFF',
    }

  },
  typography: {
    fontFamily: 'Myriad Pro Regular',
    h1: {
      fontSize: "3rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "1.875rem"
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.5rem"
      }
    },
    h2: {
      fontSize: "2.25rem",
      fontWeight: 300,
      [breakpoints.down("md")]: {
        fontSize: "1.5rem"
      }
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      [breakpoints.down("md")]: {
        fontSize: "1.125rem"
      }
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "0.75rem"
      }
    },
    h5:{
      fontSize: "1.125rem",
      fontWeight: 700,
      [breakpoints.down("md")]: {
        fontSize: "0.875rem"
      }
    },
    body1: {
      fontSize: "1.125rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "1rem"
      }
    }
  
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [myriadProRegular],
      },
    },
  },
});
//  theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAuth>
        <Router>
          <App />
        </Router>
      </ProvideAuth>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
